import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { LegendComponent, TitleComponent } from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { AngleRadar } from "../store/model/dashboard.model";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

interface Props {
  data?: AngleRadar;
}

const EchartsRadar = (props: Props) => {
  const { data } = props;

  const indicator = data?.indicator || [
    { name: "0pt(340°-20°)", max: 100 },
    { name: "0pt(300°-340°)", max: 100 },
    { name: "0pt(270°-300°)", max: 100 },
    { name: "50pt(240°-270°)", max: 100 },
    { name: "75pt(200°-240°)", max: 100 },
    { name: "100pt(160°-200°)", max: 100 },
    { name: "75pt(120°-160°)", max: 100 },
    { name: "50pt(90°-120°)", max: 100 },
    { name: "0pt(60°-90°)", max: 100 },
    { name: "0pt(20°-60°)", max: 100 },
  ];

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      tooltip: {
        trigger: "item",
        formatter: (params: any, ticket: Object, callback: Function) => {
          let headerStr = "進行方向分布詳細<br/>";
          let tipString = "";
          for (let i = 0; i < indicator.length; i++) {
            tipString += `<div style="width: 230px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;">
                            <p style="margin: 0;">
                                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${params.color}">
                                </span>${indicator[i].name}
                            </p>
                            <p style="margin: 0;">${NumberUtils.toThousands(params.value[i])}</p>
                          </div>`;
          }
          return headerStr + tipString;
        },
        position: "right",
      },
      radar: {
        indicator: indicator,
        radius: 80,
        center: ["50%", "35%"],
        axisName: {
          //show: false,
          color: "rgba(255,0,0,0)",
          backgroundColor: "rgba(255,0,0,0)",
          borderRadius: 3,
          padding: [3, 5],
        },
        axisLine: {
          // 坐标轴线
          show: false, // 默认显示，属性show控制显示与否
        },
        splitArea: {
          show: false,
          areaStyle: {
            color: "rgba(255,0,0,0)", // 图表背景网格的颜色
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: "rgba(255,0,0,0)", // 图表背景网格线的颜色
          },
        },
      },
      series: [
        {
          type: "radar",
          data: [
            {
              value: data?.series || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              symbol: "rect",
              symbolSize: 6,
              lineStyle: {
                type: "dashed",
              },
            },
          ],
        },
      ],
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox radarBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "350px", height: " 350px", top: "30px" }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EchartsRadar);
