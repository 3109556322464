import React from 'react';
import { observer } from 'mobx-react-lite';
import { AccountContext, AccountStore } from '../store/account-store';
import AccountList from './AccountList';
import { MyKeepAlive } from '../../../../core/keep-alive/MyKeepAlive';

export default observer(() => {
	return <AccountContext.Provider value={new AccountStore()}>
		<MyKeepAlive onlyOne={true}>
			<AccountList/>
		</MyKeepAlive>
	</AccountContext.Provider>;
});
