import React from 'react';
import { Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import { MenuModel } from '../../../../router/menu-data';
import { useIndexPath, useRoleType } from '../../../../hooks/use-auth';

interface IProps {
	list: MenuModel[],
	collapsed: boolean
}

const Menus = ({list, collapsed}: IProps) => {

	let location = useLocation();

	const roleType = useRoleType();
	const indexPath = useIndexPath();

	const menus = list.reduce<MenuModel[]>((m, n) => {
		const _menus = n.menus ? n.menus.map(item => ({...item, parentPath: item.path})) : [n];
		return [...m, ..._menus];
	}, []);

	const target = menus.find(item => item.path === location.pathname || item.path === location.state?.from?.pathname);

	const defaultSelectedKey = target ? target.path : indexPath(roleType);

	const defaultOpenKey = list.reduce<{ path: string, index: number } | undefined>((m, n) => {
		const index = defaultSelectedKey.indexOf(n.path);
		if (index !== -1 && (!m || (m && m?.index > index))) {
			return {path: n.path, index};
		}
		return m;
	}, undefined)?.path || '';

	const menuItem = (item: MenuModel) => {
		const {name, path, icon} = item;
		const iconCom = icon ? icon() : undefined;
		return <Menu.Item icon={iconCom} key={path}>
			<Link to={path || ''}>
				{name}
			</Link></Menu.Item>;
	};

	const MenuList = () => {
		return list.filter(item => item.enableStatus).map(item => {
			const {path, name, menus, icon} = item;
			const iconCom = icon ? icon() : undefined;
			return menus ? <Menu.SubMenu icon={iconCom} key={path} title={<span>{name}</span>}>
				{
					menus.map(item => menuItem(item))
				}
			</Menu.SubMenu> : menuItem(item);
		});
	};

	return <Menu className={collapsed ? 'mainNav navUnfold' : 'mainNav'}
				 defaultSelectedKeys={[defaultSelectedKey]}
				 defaultOpenKeys={[defaultOpenKey]}
				 mode="inline"
				 theme="dark">
		{MenuList()}
	</Menu>;
};

export default observer(Menus);

