import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Statistic } from 'antd';
import { DashboardContext } from '../../store/dashboard-store';

const ManageCustomerInfo = () => {
	const {customerInfoStore: {data: customerInfoData}} = useContext(DashboardContext);

	return <div className='customerInfo'>
		<h2>顧客情報</h2>
		<Row gutter={16}>
			<Col span={8}>
				<Statistic title="登録アカウント数" value={customerInfoData?.accountCount || 0}/>
			</Col>
			<Col span={8}>
				<Statistic title="登録媒体数" value={customerInfoData?.mediaCount || 0}/>
			</Col>
			<Col span={8}>
				<Statistic title="登録代理店数" value={customerInfoData?.agencyCount || 0}/>
			</Col>
		</Row>
	</div>;
};

export default observer(ManageCustomerInfo);
