import React, {useContext, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, DatePicker, Form, TreeSelect} from 'antd';
import moment from 'moment';
import {DATE} from '../../../../constants';
import {DashboardContext} from '../../store/dashboard-store';

interface ItemProps {
    title: string;
    value: number;
}

const {SHOW_PARENT} = TreeSelect;

let timeout: any;

const ManageForm = () => {

    const [form] = Form.useForm();

    const {
        summaryStore: {loadData},
        mediaSelListStore: {loadData: loadMediaListData, list: mediaList},
    } = useContext(DashboardContext);

    const today: string = moment().format('YYYYMMDD');

    useEffect(() => {
        loadMediaListData().then();
        loadSummaryData(null, today);
    }, [loadMediaListData]);

    useEffect(() => {
        return () => clearTimerLoad();
    }, []);

    const clearTimerLoad = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    const startTimerLoad = (mediaIds: any, date: string) => {
        clearTimerLoad();
        timeout = setTimeout(() => {
            loadSummaryData(mediaIds, date);
        }, 15 * 1000);
    };

    const loadSummaryData = (mediaIds: any, date: string) => {
        loadData({mediaIds: mediaIds, date: date}).then(({success}) => {
            if (success) {
                if (date === today) {
                    startTimerLoad(mediaIds, date);
                } else {
                    clearTimerLoad();
                }
            }
        });
    };

    const onFinish = async (values: any) => {
        if (values.mediaIds.length !== 0) {
            let mediaIds = null;
            if (values.mediaIds[0] !== 0) {
                mediaIds = values.mediaIds;
            }
            const date = moment(values.date).format('YYYYMMDD');
            if (date !== today) {
                clearTimerLoad();
            }
            loadSummaryData(mediaIds, date);
        }
    };

    const options: ItemProps[] = [];

    for (let i = 0; i < mediaList.length; i++) {
        options.push({
            title: mediaList[i].name,
            value: mediaList[i].id,
        });
    }

    const [value, setValue] = React.useState([0]);

    const treeData = [
        {
            title: '全部',
            value: 0,
            children: options,
        },
    ];

    const tProps = {
        treeData,
        value,
        onChange: (newValue: number[]) => {
            setValue(newValue);
        },
        treeCheckable: true,
        showArrow: true,
        showCheckedStrategy: SHOW_PARENT,
        className: 'multipleSelect',
        treeDefaultExpandAll: true,
        placeholder: '媒体を選択してください',
        maxTagCount: 'responsive' as const,
    };

    return <>
        <Form form={form}
              onFinish={onFinish}
              className='form'
              layout="inline"
        >
            <Form.Item name="date" initialValue={moment(moment().format(DATE))}
                       rules={[{required: true, message: '媒体を選択してください'}]}>
                <DatePicker allowClear={false}/>
            </Form.Item>
            <Form.Item name="mediaIds" initialValue={value} rules={[{required: true, message: '媒体を選択してください'}]}>
                <TreeSelect {...tProps}/>
            </Form.Item>
            <Form.Item>
                <Button type='primary' onClick={() => form.submit()}>検索</Button>
            </Form.Item>
        </Form>
    </>;
};

export default observer(ManageForm);
