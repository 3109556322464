import React, { useContext } from "react";
import { Button } from "antd";
import MyTable from "../../../../../components/MyTable";
import { AgencyUserContext } from "../../store/agency-user-store";
import { PlusOutlined } from "@ant-design/icons/lib";
import columns from "./columns";
import { observer } from "mobx-react-lite";
import { AgencyContext } from "../../store/agency-store";

const AgencyUserTable = () => {
  const { listStore, modalStore, delUserStore } = useContext(AgencyUserContext);
  const { detailStore } = useContext(AgencyContext);

  const { openModal } = modalStore;

  return (
    <MyTable
      listStore={listStore}
      columns={columns(modalStore, delUserStore, detailStore)}
      rowKey={"id"}
      headerTitle="ユーザ一覧"
      search={false}
      toolBarRender={() => [
        <Button type="primary" key="primary" onClick={() => openModal()}>
          <PlusOutlined />
          新規登録
        </Button>,
      ]}
    />
  );
};
export default observer(AgencyUserTable);
