import React, { useContext, useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import { LineChart } from "echarts/charts";
import { DashboardContext } from "../store/dashboard-store";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

const EchartsStackedArea = () => {
  const {
    summaryStore: { data },
    dataZoom,
  } = useContext(DashboardContext);

  let onEvents = {
    dataZoom: (event: any) => {
      if (event.batch) {
        dataZoom[0] = {
          type: "inside",
          start: event.batch[0].start,
          end: event.batch[0].end,
        };
        dataZoom[1] = {
          start: event.batch[0].start,
          end: event.batch[0].end,
          labelFormatter: () => {
            return "";
          },
        };
      } else {
        dataZoom[0] = {
          type: "inside",
          start: event.start,
          end: event.end,
        };
        dataZoom[1] = {
          start: event.start,
          end: event.end,
          labelFormatter: () => {
            return "";
          },
        };
      }
    },
  };

  let tmp: any = [];
  for (let i = 0; i < data?.realtimeAvsTrend?.legends.length; i++) {
    const name = data?.realtimeAvsTrend?.legends[i];
    const datas = data?.realtimeAvsTrend?.series[name];
    const temp = {
      name: name,
      type: "line",
      stack: "Total",
      symbol: "none",
      areaStyle: {},
      lineStyle: {
        width: 1,
      },
      emphasis: {
        focus: "series",
      },
      data: datas,
    };
    tmp.push(temp);
  }

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      title: [
        {
          text: "",
          left: "center",
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: Array<any>, ticket: Object, callback: Function) => {
          let headerStr = params[0].axisValue + "<br />";
          let sum = 0;
          let totalStr = "";
          let tipString = "";
          let indexColor;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="width: 150px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${
              params[i].seriesName
            }</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
            sum += parseInt(params[i].value);
          }
          totalStr += `<div style="width: 150px; margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style=" margin: 0;">Total</p><p style="margin: 0;">${NumberUtils.toThousands(
            sum
          )}</p></div>`;
          tipString = headerStr + totalStr + tipString;
          return tipString;
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: data?.realtimeAvsTrend.xAxis || [],
        },
      ],
      legend: {
        icon: "circle",
        data: data?.realtimeAvsTrend.legends || [],
        padding: 20,
      },
      yAxis: [
        {
          type: "value",
        },
      ],
      dataZoom: dataZoom,
      series: tmp,
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "1160px", height: " 460px" }}
        ref={echartsEl}
        onEvents={onEvents}
      />
    </div>
  );
};

export default observer(EchartsStackedArea);
