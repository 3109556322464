import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_REPORT } from "../../../../router/router-path";
import { useRoleBack } from "../../../../hooks/use-auth";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons/lib";
import { ReportContext } from "../../store/report-store";
import { useFormInit } from "../../../../core/form";
import { useAliveNotify } from "../../../../core/keep-alive/use-keep-alive";
import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  AdTimeModel,
  ReportExportCondition,
} from "../../store/model/report.model";
import TimeMMss from "../../../../components/TimeMMss";
import UserTable from "../../../../components/UserTable";
import { MediaSelModel } from "../../../user/setting/store/model/setting.model";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const outputLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const roleBack = useRoleBack();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const [noneAgency, setNoneAgency] = useState(false);
  const [days, setDays] = useState(0);
  const [adFee, setAdFee] = useState(0);

  const reportStore = useContext(ReportContext);
  const {
    detailStore: { data, loadData, isBusy: loading },
    mediaSelListStore: { list: medias, loadData: loadMedias },
    agencySelListStore: { list: agencies, loadData: loadAgencies },
    editStore: { submit, isBusy },
    agencyUsersStore: {
      loadData: loadUsers,
      list: allUsers,
      clear: clearUsers,
    },
    timeSpecificationCheckStatus,
  } = reportStore;

  useEffect(() => {
    if (params?.id) {
      Promise.all([loadData({ id: params?.id }), loadMedias()]).then((res) => {
        const [res1, res2] = res;
        if (res1.success && res2.success) {
          const mediaIds: number[] = (res1.data?.medias || []).map(
            (item) => item.id
          );
          let adStartDate = moment(res1.data?.adStartDate, "YYYY/MM/DD");
          let adEndDate = moment(res1.data?.adEndDate, "YYYY/MM/DD");
          form.setFieldsValue({
            adPeriod: [adStartDate, adEndDate],
            mediaIds: mediaIds,
          });
          let durationDays = adEndDate.diff(adStartDate, "day") + 1;
          setDays(durationDays);
          setNoneAgency(res1.data?.agencyId == null);
          //if (res1.data) setAdFee(res1.data.adFee);

          if (res1.data?.agencyId != null) {
            loadUsers({ id: res1.data?.agencyId + "" }).then();
          }
          if (window.location.pathname.startsWith("/report/copy/")) {
            form.setFieldsValue({ id: null });
          }
          mediaSelectChange(mediaIds, res2.data);
        }
      });
    } else {
      loadMedias().then();
    }
    loadAgencies().then();
  }, [loadData, loadMedias, loadAgencies, loadUsers, window.location]);

  useFormInit(form, data);

  const onFinish = async (values: any) => {
    console.log(values);
    if (typeof values["mediaIds"] === "number") {
      values["mediaIds"] = [values["mediaIds"]];
    }
    const { success } = await submit(values);
    if (success) {
      aliveNotify();
      navigate(PATH_REPORT);
    }
  };

  const handleBack = () => {
    roleBack(() => {
      navigate(PATH_REPORT);
    });
  };

  // @ts-ignore
  const arrayForOptionHour = [...new Array(26).keys()];

  const changeStartTime = (
    conditionIdx: number,
    index: number,
    value: string
  ) => {
    const fieldsData = form.getFieldsValue();
    let materialScale = fieldsData.exportConditions[conditionIdx].materialScale;
    let autoFillEndTime = moment(value, "mm:ss")
      .add(materialScale, "seconds")
      .format("mm:ss");

    let _items = fieldsData.exportConditions[conditionIdx].hourlyAdTimes.map(
      (_item: AdTimeModel, _idx: number) => {
        return _idx === index
          ? { ..._item, startTime: value, endTime: autoFillEndTime }
          : _item;
      }
    );

    form.setFieldsValue({
      exportConditions: fieldsData.exportConditions.map(
        (ec: ReportExportCondition, _index: number) => {
          return _index === conditionIdx
            ? { ...ec, hourlyAdTimes: _items }
            : ec;
        }
      ),
    });
  };

  const recalculateAdCount = (conditionIdx: number) => {
    const adStartDate = form.getFieldValue("adStartDate");
    const adEndDate = form.getFieldValue("adEndDate");
    const exportConditions = form.getFieldValue("exportConditions");
    let countPerHour = exportConditions[conditionIdx].hourlyAdTimes.length;
    let timeSpecification = exportConditions[conditionIdx].timeSpecification;
    let adStartHourMinute = exportConditions[conditionIdx].adStartTime;
    let adEndHourMinute = exportConditions[conditionIdx].adEndTime;

    let durationHours: number;
    if (timeSpecification === 1) {
      if (adStartHourMinute != null && adEndHourMinute != null) {
        let startHour = adStartHourMinute.split(":")[0];
        let endHour = adEndHourMinute.split(":")[0];
        durationHours = Number(endHour) - Number(startHour);
      } else {
        durationHours = 0;
      }
    } else {
      durationHours = endTime - startTime;
    }
    let adCountPerDay = durationHours * countPerHour;
    form.setFieldsValue({
      exportConditions: exportConditions.map(
        (ec: ReportExportCondition, _index: number) => {
          if (adStartDate != null && adEndDate != null) {
            let durationDays =
              moment(adEndDate).diff(moment(adStartDate), "day") + 1;
            let adCountOfPeriod = durationDays * adCountPerDay;
            return _index === conditionIdx
              ? {
                  ...ec,
                  adCountPerDay: adCountPerDay,
                  adCountOfPeriod: adCountOfPeriod,
                  adCountPerHour: ec.hourlyAdTimes.length,
                }
              : ec;
          } else {
            return _index === conditionIdx
              ? {
                  ...ec,
                  adCountPerDay: adCountPerDay,
                  adCountPerHour: ec.hourlyAdTimes.length,
                }
              : ec;
          }
        }
      ),
    });
  };

  const recalculateAdCountOfPeriod = (dateStrings: string[]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    const exportConditions = form.getFieldValue("exportConditions");
    if (startDate && endDate && exportConditions.length > 0) {
      const adStartDate = moment(startDate, "YYYY/MM/DD (ddd)");
      const adEndDate = moment(endDate, "YYYY/MM/DD (ddd)");
      let durationDays = adEndDate.diff(adStartDate, "day") + 1;
      setDays(durationDays);
      form.setFieldsValue({
        adStartDate: adStartDate.format("YYYY/MM/DD"),
        adEndDate: adEndDate.format("YYYY/MM/DD"),
        adPeriod: [adStartDate, adEndDate],
        exportConditions: exportConditions.map(
          (ec: ReportExportCondition, _index: number) => {
            let countPerHour = ec.hourlyAdTimes.length;
            let adStartHourMinute = ec.adStartTime;
            let adEndHourMinute = ec.adEndTime;

            let durationHours: number;
            if (ec.timeSpecification === 1) {
              if (adStartHourMinute != null && adEndHourMinute != null) {
                let startHour = adStartHourMinute.split(":")[0];
                let endHour = adEndHourMinute.split(":")[0];
                durationHours = Number(endHour) - Number(startHour);
              } else {
                durationHours = 0;
              }
            } else {
              durationHours = endTime - startTime;
            }

            if (ec.hourlyAdTimes.length > 0) {
              let adCountPerDay = durationHours * countPerHour;
              let adCountOfPeriod = durationDays * adCountPerDay;
              return {
                ...ec,
                adCountPerDay: adCountPerDay,
                adCountOfPeriod: adCountOfPeriod,
                adCountPerHour: ec.hourlyAdTimes.length,
              };
            }
            return ec;
          }
        ),
      });
    }
  };

  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(25);

  const mediaSelectChange = (list: number[], __medias?: MediaSelModel[]) => {
    if (list.length === 0) {
      setStartTime(Number(0));
      setEndTime(Number(25));
    } else {
      let opStartTime = 0;
      let opEndTime = 0;
      list.forEach((_value) => {
        const _medias = (__medias || medias).find(
          (value) => value.id === _value
        );
        if (opStartTime === 0 && opEndTime === 0) {
          opStartTime = Number(_medias?.opStartTime.split(":")[0]) || 0;
          opEndTime = Number(_medias?.opEndTime.split(":")[0]) || 0;
        } else {
          opStartTime =
            Number(_medias?.opStartTime.split(":")[0]) > opStartTime
              ? Number(_medias?.opStartTime.split(":")[0])
              : opStartTime;
          opEndTime =
            Number(_medias?.opEndTime.split(":")[0]) < opEndTime
              ? Number(_medias?.opEndTime.split(":")[0])
              : opEndTime;
        }
      });
      setStartTime(Number(opStartTime));
      setEndTime(Number(opEndTime));
    }
  };

  const handleAgencySelect = (selectedId: string) => {
    if (selectedId) {
      loadUsers({ id: selectedId }).then();
    } else {
      clearUsers();
    }
  };

  return (
    <Spin tip="読み込み..." spinning={loading}>
      <Form form={form} {...layout} onFinish={onFinish} className="form">
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item name="id" label="レポートID">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              name="owner"
              label="広告主名"
              rules={[
                {
                  required: true,
                  message: "広告主名を入力してください",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="広告内容（タイトル等）"
              rules={[
                {
                  required: true,
                  message: "広告内容（タイトル等）を入力してください",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mediaIds"
              label="対象媒体"
              rules={[
                { required: true, message: "対象媒体を選択してください" },
              ]}
            >
              <Select
                placeholder="対象媒体選択"
                allowClear={true}
                onChange={(list: any) => mediaSelectChange([list])}
              >
                {medias.map((media) => (
                  <Option value={media.id}>{media.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={noneAgency}
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox
                checked={noneAgency}
                onChange={(e) => setNoneAgency(e.target.checked)}
              >
                代理店選択しない
              </Checkbox>
            </Form.Item>
            {!noneAgency && (
              <Form.Item
                name="agencyId"
                label="対象代理店"
                rules={[
                  { required: true, message: "対象代理店を選択してください" },
                ]}
              >
                <Select
                  onChange={(e: string) => handleAgencySelect(e)}
                  disabled={noneAgency}
                  placeholder="対象代理店選択"
                  allowClear={true}
                >
                  {agencies.map((agency) => (
                    <Option value={agency.id}>{agency.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {/*<Row gutter={[8, 24]}>*/}
            {/*  <Col offset={3}>*/}
            <Form.Item label="広告放映期間" className={"label_require"}>
              <Form.Item
                name="adPeriod"
                rules={[
                  {
                    required: true,
                    message: "広告放映期間を入力してください",
                  },
                ]}
                noStyle
              >
                <RangePicker
                  onChange={(_, dateStrings) =>
                    recalculateAdCountOfPeriod(dateStrings)
                  }
                  format="YYYY/MM/DD (ddd)"
                  placeholder={["", ""]}
                />
              </Form.Item>
              <span className="ant-form-text">（{days}日間）</span>
            </Form.Item>
            {/*  </Col>*/}
            {/*</Row>*/}
            <Form.Item hidden={true} name="adStartDate"></Form.Item>
            <Form.Item hidden={true} name="adEndDate"></Form.Item>

            <Form.Item label="広告放映料金">
              <Form.Item name="adFee" noStyle>
                <InputNumber min={0} />
              </Form.Item>
              <span className="ant-form-text"> 円</span>
            </Form.Item>
            {/*<Form.Item*/}
            {/*  label="■ 目標設定（1日合計）"*/}
            {/*  style={{ marginBottom: 0 }}*/}
            {/*  labelCol={{ offset: 5, span: 24 }}*/}
            {/*  wrapperCol={{ offset: 5, span: 24 }}*/}
            {/*>*/}
            {/*  <Row gutter={[8, 24]}>*/}
            {/*    <Col span={8}>*/}
            {/*    </Col>*/}
            {/*    /!*<Col span={8}>*!/*/}
            {/*    /!*  <Form.Item name="goalImpression" label="Impression数">*!/*/}
            {/*    /!*    <InputNumber min={0} />*!/*/}
            {/*    /!*  </Form.Item>*!/*/}
            {/*    /!*</Col>*!/*/}
            {/*    /!*<Col span={8}>*!/*/}
            {/*    /!*  <Form.Item name="goalAvs" label="AVS">*!/*/}
            {/*    /!*    <InputNumber min={0} />*!/*/}
            {/*    /!*  </Form.Item>*!/*/}
            {/*    /!*</Col>*!/*/}
            {/*  </Row>*/}
            {/*</Form.Item>*/}
          </Col>
          <Col span={10}>
            {!noneAgency && (
              <Form.Item
                name="userIds"
                label="対象ユーザ"
                labelCol={{ offset: 4, span: 24 }}
                wrapperCol={{ offset: 4, span: 24 }}
                rules={[
                  { required: true, message: "対象ユーザを選択してください" },
                ]}
              >
                <UserTable allUsers={allUsers} />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Form.List
          initialValue={[{ hourlyAdTimes: [{ startTime: "", endTime: "" }] }]}
          name="exportConditions"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, conditionIdx) => {
                return (
                  <div key={field.key}>
                    <Row gutter={24}>
                      <Col span={22}>
                        <div key={field.key}>
                          <Divider orientation="left">{`レポート出力条件${
                            conditionIdx + 1
                          }`}</Divider>
                          <Form.Item {...outputLayout} label="広告放映時間">
                            <Form.Item
                              name={[field.name, "timeSpecification"]}
                              fieldKey={[field.fieldKey, "timeSpecification"]}
                            >
                              <Radio.Group
                                defaultValue={0}
                                onChange={(e) =>
                                  reportStore.updateTimeSpecificationCheckStatus(
                                    conditionIdx,
                                    e.target.value
                                  )
                                }
                              >
                                <Radio value={0}>終日</Radio>
                                <Radio value={1}>時間指定</Radio>
                                <Radio value={2}>媒体運営時間全て</Radio>
                              </Radio.Group>
                            </Form.Item>
                            {!!timeSpecificationCheckStatus[conditionIdx] &&
                              timeSpecificationCheckStatus[conditionIdx] ==
                                1 && (
                                <Space align="baseline">
                                  <Form.Item
                                    name={[field.name, "adStartTime"]}
                                    fieldKey={[field.fieldKey, "adStartTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "開始時間を選択してください",
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: 90 }}
                                      onChange={() =>
                                        recalculateAdCount(conditionIdx)
                                      }
                                    >
                                      {arrayForOptionHour.map((value) => {
                                        const _value =
                                          value < 10
                                            ? `0${value}:00`
                                            : `${value}:00`;
                                        return (
                                          <Option
                                            disabled={
                                              value < startTime ||
                                              value > endTime
                                            }
                                            value={_value}
                                          >
                                            {_value}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                  〜
                                  <Form.Item
                                    name={[field.name, "adEndTime"]}
                                    fieldKey={[field.fieldKey, "adEndTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "終了時間を選択してください",
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: 90 }}
                                      onChange={() =>
                                        recalculateAdCount(conditionIdx)
                                      }
                                    >
                                      {arrayForOptionHour.map((value) => {
                                        const _value =
                                          value < 10
                                            ? `0${value}:00`
                                            : `${value}:00`;
                                        return (
                                          <Option
                                            disabled={
                                              value < startTime ||
                                              value > endTime
                                            }
                                            value={_value}
                                          >
                                            {_value}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Space>
                              )}
                          </Form.Item>

                          {timeSpecificationCheckStatus[conditionIdx] != 2 && (
                            <>
                              <Form.Item
                                {...outputLayout}
                                name={[field.name, "materialName"]}
                                fieldKey={[field.fieldKey, "materialName"]}
                                label="素材名"
                              >
                                <Input
                                  maxLength={50}
                                  style={{ width: "50%" }}
                                />
                              </Form.Item>

                              <Form.Item
                                {...outputLayout}
                                name={[field.name, "materialScale"]}
                                fieldKey={[field.fieldKey, "materialScale"]}
                                label="素材尺"
                                rules={[
                                  {
                                    required: true,
                                    message: "素材尺を選択してください",
                                  },
                                ]}
                              >
                                <Select style={{ width: "20%" }}>
                                  <Option value={15}>15秒</Option>
                                  <Option value={30}>30秒</Option>
                                  <Option value={45}>45秒</Option>
                                  <Option value={60}>60秒</Option>
                                  <Option value={75}>75秒</Option>
                                  <Option value={90}>90秒</Option>
                                  <Option value={120}>120秒</Option>
                                  <Option value={180}>3分</Option>
                                  <Option value={300}>5分</Option>
                                  <Option value={600}>10分</Option>
                                </Select>
                              </Form.Item>
                              <Row gutter={24}>
                                <Col span={18}>
                                  <Form.Item
                                    label="毎時放映時間"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "毎時放映時間を選択してください",
                                      },
                                    ]}
                                  >
                                    <Form.List
                                      initialValue={[]}
                                      name={[field.name, "hourlyAdTimes"]}
                                    >
                                      {(fields, { add, remove }) => (
                                        <>
                                          {fields.map((field, index) => (
                                            <Space
                                              key={field.key}
                                              align="baseline"
                                            >
                                              <Form.Item
                                                label="開始(分秒)"
                                                name={[field.name, "startTime"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "startTime",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "開始(分秒)を入力してください",
                                                  },
                                                  {
                                                    type: "string",
                                                    min: 5,
                                                    message:
                                                      "開始(分秒)を入力してください",
                                                  },
                                                ]}
                                              >
                                                <TimeMMss
                                                  onChange={(e) =>
                                                    changeStartTime(
                                                      conditionIdx,
                                                      index,
                                                      e
                                                    )
                                                  }
                                                ></TimeMMss>
                                                {/*<Input*/}
                                                {/*  onChange={ (e) => changeStartTime(conditionIdx, index, e.target.value) }/>*/}
                                              </Form.Item>
                                              <Form.Item
                                                label="終了(分秒)"
                                                name={[field.name, "endTime"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "endTime",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "終了(分秒)を入力してください",
                                                  },
                                                ]}
                                              >
                                                <Input disabled={true} />
                                              </Form.Item>
                                              {fields.length > 1 && (
                                                <MinusCircleOutlined
                                                  onClick={() => {
                                                    remove(index);
                                                    recalculateAdCount(
                                                      conditionIdx
                                                    );
                                                  }}
                                                />
                                              )}
                                            </Space>
                                          ))}
                                          <Form.Item>
                                            <Button
                                              style={{ width: "90%" }}
                                              type="dashed"
                                              onClick={() => {
                                                add();
                                                recalculateAdCount(
                                                  conditionIdx
                                                );
                                              }}
                                              block
                                              icon={<PlusOutlined />}
                                            >
                                              追加
                                            </Button>
                                          </Form.Item>
                                        </>
                                      )}
                                    </Form.List>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, "adCountPerHour"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "adCountPerHour",
                                    ]}
                                    label="毎時放映回数"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, "adCountPerDay"]}
                                    fieldKey={[field.fieldKey, "adCountPerDay"]}
                                    label="放映回数（日）"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, "adCountOfPeriod"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "adCountOfPeriod",
                                    ]}
                                    label="放映回数（期間）"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col span={1} offset={1} style={{ marginTop: "18px" }}>
                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            translate="yes"
                            onClick={() => {
                              remove(conditionIdx);
                              reportStore.removeTimeSpecificationCheckStatus(
                                conditionIdx
                              );
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Form.Item
                hidden={fields.length >= 3}
                wrapperCol={{ span: 20, offset: 2 }}
              >
                <Button
                  type="dashed"
                  onClick={() => {
                    add({
                      hourlyAdTimes: [{ startTime: "", endTime: "" }],
                      timeSpecification: 0,
                    });
                    reportStore.addTimeSpecificationCheckStatus(0);
                  }}
                  block
                  icon={<PlusOutlined translate="yes" />}
                >
                  レポート出力条件を追加する
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => handleBack()}>キャンセル</Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => form.submit()}
                loading={isBusy}
              >
                {params.id
                  ? window.location.pathname.startsWith("/report/copy/")
                    ? "登録"
                    : "更新"
                  : "登録"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(ReportEditForm);
