import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardContext } from '../../store/dashboard-store';
import { Descriptions } from "antd";

interface Props {
	mediaID?: number
}

const MediaInfo = (props: Props) => {
	const {mediaID} = props;

	const {mediaListStore: {loadData: loadMediaListData, list: mediaList}} = useContext(DashboardContext);

	useEffect(() => {
		loadMediaListData().then();
	}, [loadMediaListData]);

	const target = mediaList.find(item => Number(item.id) === mediaID);

	return <Descriptions bordered>
			<Descriptions.Item label="媒体名称" span={3}>{target?.name}</Descriptions.Item>
			<Descriptions.Item label="媒体所在地" span={3}>{target?.location}</Descriptions.Item>
			<Descriptions.Item label="運営時間" span={3}>{target?.opStartTime} 〜 {target?.opEndTime}</Descriptions.Item>
			<Descriptions.Item label="音声" span={3}>
				{(target?.hasAudio ? "音声あり" : "音声なし") + (target?.audioComment != null ? "（" + target?.audioComment + "）" : "")}
			</Descriptions.Item>
		</Descriptions>;
};

export default observer(MediaInfo);
