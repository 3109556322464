import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([GridComponent, BarChart, CanvasRenderer]);

interface Props {
  data?: { xAxis: string[]; series: number[] };
}

const EchartsBar = (props: Props) => {
  const { data } = props;
  const seriesData: Array<object> = [];
  for (let i = 0; i < (data?.xAxis || []).length; i++) {
    seriesData.push({
      name: data?.xAxis[i],
      data: [data?.series[i]],
      type: "bar",
    });
  }
  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      colorBy: "series",
      title: {
        text: "",
        left: "center",
      },
      xAxis: {
        type: "category",
        data: [""],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          interval: 0,
        },
      },
      grid: {
        top: 30,
        left: 80,
        right: 25,
      },
      tooltip: {
        trigger: "axis",
        textStyle: {
          align: "left",
        },
      },
      legend: {
        icon: "circle",
        bottom: "left",
        data: data?.xAxis || [],
        formatter: (name: string) => {
          // @ts-ignore
          let legend = seriesData.filter((item) => item.name === name)[0];
          return [
            // @ts-ignore
            `{name|${legend?.name}}`,
            // @ts-ignore
            `{value|${NumberUtils.toThousands(legend?.data[0])}}`,
          ].join("  ");
        },
        textStyle: {
          rich: {
            name: {
              fontSize: 12,
            },
            value: {
              fontSize: 12,
              fontWeight: 900,
            },
          },
        },
      },
      series: seriesData,
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "340px", height: " 350px" }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EchartsBar);
