import { useEffect } from 'react';
import EventBus from '../core/event-bus';
import { PATH_LOGIN } from '../router/router-path';
import { useMyNavigate } from '../core/navigate';
import { EVENT_GO_TO_LOGIN_KEY } from '../constants';

export const useLoginListener = () => {

	let navigate = useMyNavigate();

	useEffect(() => {
		if (!EventBus.eventNames().some(item => item === EVENT_GO_TO_LOGIN_KEY)) {
			EventBus.once(EVENT_GO_TO_LOGIN_KEY, () => {
				navigate(PATH_LOGIN);
			});
		}
		return () => {
			EventBus.off(EVENT_GO_TO_LOGIN_KEY, () => {
			});
		};
		// eslint-disable-next-line
	}, []);
};
