import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Divider } from "antd";
import AgencyEditForm from "./components/AgencyEditForm";
import { PATH_AGENCY } from "../../../../router/router-path";
import { useNavigate, useParams } from "react-router-dom";
import MyDelConfirm from "../../../../components/MyDelConfirm";
import { AgencyContext } from "../store/agency-store";
import { AgencyUserContext, AgencyUserStore } from "../store/agency-user-store";
import AgencyUserTable from "./components/AgencyUserTable";
import AgencyUserModal from "./components/AgencyUserModal";
import { useRoleType } from "../../../../hooks/use-auth";
import { RoleType } from "../../../../store/user.model";
import { useAliveNotify } from "../../../../core/keep-alive/use-keep-alive";

const AgencyEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const aliveNotify = useAliveNotify();

  const {
    delStore: { submit },
  } = useContext(AgencyContext);

  const roleType = useRoleType();

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>設定・登録</Breadcrumb.Item>
        <Breadcrumb.Item>代理店{params.id ? `変更` : "登録"}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard editCard">
          {params.id &&
            (roleType === RoleType.ADMIN || roleType === RoleType.AD_MEDIA) && (
              <div className="cardHeader">
                <MyDelConfirm
                  handleDel={() => submit({ id: params?.id })}
                  delSuccess={() => {
                    aliveNotify();
                    navigate(PATH_AGENCY);
                  }}
                />
              </div>
            )}
          <div className="cardBody">
            <AgencyEditForm />
            {params.id && (
              <AgencyUserContext.Provider
                value={new AgencyUserStore(params.id)}
              >
                <Divider />
                <AgencyUserTable />
                <AgencyUserModal />
              </AgencyUserContext.Provider>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AgencyEdit);
