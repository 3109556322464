import { AppstoreOutlined, PieChartOutlined, ProjectOutlined, SettingOutlined } from '@ant-design/icons/lib';
import * as React from 'react';
import {
	PATH_ACCOUNT,
	PATH_AGENCY,
	PATH_DASHBOARD,
	PATH_DASHBOARD_ACCOUNT,
	PATH_DASHBOARD_MANAGE,
	PATH_DASHBOARD_MEDIA,
	PATH_MANAGE,
	PATH_MEDIA,
	PATH_REPORT,
	PATH_SETTING,
} from './router-path';
import { RoleType } from '../store/user.model';

/**
 * MenuModel
 */
export interface MenuModel {
	name: string
	role: RoleType[]
	path: string
	icon?: () => React.ReactNode
	menus?: MenuModel[]
	enableStatus: number
	parentPath?: string
}

export const getCurrentMenus = (role: RoleType): MenuModel[] => {
	return menusList.filter(item => item.role.some(r => r === role))
		.map(item => item.menus ? {
			...item,
			menus: item.menus.filter(item => item.role.some(r => r === role)),
		} : item);
};

/**
 * Menu List
 */
export const menusList: MenuModel[] = [
	{
		name: '設定・登録',
		path: PATH_MANAGE,
		role: [RoleType.ADMIN, RoleType.AD_MEDIA],
		icon: () => <AppstoreOutlined />,
		enableStatus: 1,
		menus: [
			{
				name: 'アカウント',
				path: `${PATH_ACCOUNT}`,
				role: [RoleType.ADMIN, RoleType.AD_MEDIA],
				enableStatus: 1,
			},
			{
				name: '媒体',
				path: `${PATH_MEDIA}`,
				role: [RoleType.ADMIN, RoleType.AD_MEDIA],
				enableStatus: 1,
			},
			{
				name: '代理店',
				path: `${PATH_AGENCY}`,
				role: [RoleType.ADMIN, RoleType.AD_MEDIA],
				enableStatus: 1,
			},
		],
	},
	{
		name: 'ダッシュボード',
		path: PATH_DASHBOARD,
		role: [RoleType.ADMIN, RoleType.AD_MEDIA],
		icon: () => <PieChartOutlined/>,
		enableStatus: 1,
		menus: [
			{
				name: '全体',
				role: [RoleType.ADMIN],
				path: `${PATH_DASHBOARD_MANAGE}`,
				enableStatus: 1,
			},
			{
				name: '全体',
				role: [RoleType.AD_MEDIA],
				path: `${PATH_DASHBOARD_ACCOUNT}`,
				enableStatus: 1,
			},
			{
				name: '媒体',
				role: [RoleType.AD_MEDIA],
				path: `${PATH_DASHBOARD_MEDIA}`,
				enableStatus: 1,
			},
		],
	},
	{
		name: 'レポート',
		path: `${PATH_REPORT}`,
		role: [RoleType.ADMIN, RoleType.AD_MEDIA, RoleType.USER],
		icon: () => <ProjectOutlined/>,
		enableStatus: 1,
	},
	{
		name: '設定',
		path: PATH_SETTING,
		role: [RoleType.ADMIN],
		icon: () => <SettingOutlined/>,
		enableStatus: 1,
	},
];
