import React from 'react';
import { observer } from 'mobx-react-lite';
import { MyKeepAlive } from '../../../core/keep-alive/MyKeepAlive';
import { DashboardStore, DashboardContext } from '../store/dashboard-store';
import MediaMain from './MediaMain';

export default observer(() => {
	return <DashboardContext.Provider value={new DashboardStore()}>
		<MyKeepAlive onlyOne={true}>
			<MediaMain/>
		</MyKeepAlive>
	</DashboardContext.Provider>;
});
