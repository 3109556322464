import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_LOGIN } from './router-path';
import { useAuth } from '../hooks/use-auth';

const AuthProvider = ({children}: { children: JSX.Element }) => {
	let auth = useAuth();
	let location = useLocation();

	if (!auth) {
		return <Navigate to={PATH_LOGIN} state={{from: location}}/>;
	}

	return children;
};


export default AuthProvider;
