import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Descriptions,
  Row,
  Select,
  Table,
  Space
} from "antd";
import "./index.scss";
import AdPeriod from "./components/AdPeriod";
import ReportDate from "./components/ReportDate";
import { PATH_REPORT } from "../../../router/router-path";
import { useRoleBack } from "../../../hooks/use-auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  FilePdfOutlined,
  RollbackOutlined,
} from "@ant-design/icons/lib";
import { ReportContext } from "../store/report-store";
import moment from "moment";
import ReactToPrint from "react-to-print";

const { Option } = Select;

const { Panel } = Collapse;
const { Column, ColumnGroup } = Table;
const ReportDetails = () => {
  const columns: any = [
    {
      title: "開始時間(分秒)",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
    },
    {
      title: "終了時間(分秒)",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
    },
  ];

  const navigate = useNavigate();
  const roleBack = useRoleBack();
  const params = useParams();
  const handleBack = () => {
    roleBack(() => {
      navigate(PATH_REPORT);
    });
  };

  let printArea: any;

  const handlePDF = () => {
    window.open("/imgs/AVS指標について.pdf");
  };

  const {
    detailStore: { data: reportDef, loadData: loadReportDefData },
    dateStore: { list: dateList, loadData: loadDateList },
    viewDateStore: { loadData: loadViewDateData },
  } = useContext(ReportContext);

  useEffect(() => {
    if (params?.id) {
      loadReportDefData({ id: params?.id }).then();
      loadDateList({ id: params.id }).then(({ data }) => {
        if (data) {
          loadViewDateData({ id: params.id, date: data[0] }).then();
        }
      });
    }
  }, [loadReportDefData, params?.id]);

  const getDateTitle = () => {
    if (dateList?.length) {
      //return `日（${moment(dateList[0]).format("M月D日")}）`;
      return "日別表示";
    }
    return "";
  };

  const getExportConditionsAllDay = () => {
    if (reportDef?.exportConditions.length) {
      const [first] = reportDef?.exportConditions;
      return first.timeSpecification == 1
        ? `${first.adStartTime}~${first.adEndTime}`
        : first.timeSpecification == 0
        ? "終日"
        : "媒体運営時間全て";
    }
    return "";
  };
  const getExportConditionsAllDay2 = () => {
    if (reportDef?.exportConditions.length >= 2) {
      const [, second] = reportDef?.exportConditions;
      return second.timeSpecification == 1
        ? `${second.adStartTime}~${second.adEndTime}`
        : second.timeSpecification == 0
        ? "終日"
        : "媒体運営時間全て";
    }
    return "";
  };
  const getExportConditionsAllDay3 = () => {
    if (reportDef?.exportConditions.length >= 3) {
      const [, , third] = reportDef?.exportConditions;
      return third.timeSpecification == 1
        ? `${third.adStartTime}~${third.adEndTime}`
        : third.timeSpecification == 0
        ? "終日"
        : "媒体運営時間全て";
    }
    return "";
  };

  const getExportConditionsFirst = () => {
    if (reportDef?.exportConditions.length) {
      return reportDef?.exportConditions[0];
    }
    return {};
  };
  const getExportConditionsSec = () => {
    if (reportDef?.exportConditions.length >= 2) {
      return reportDef?.exportConditions[1];
    }
    return {};
  };
  const getExportConditionsThr = () => {
    if (reportDef?.exportConditions.length === 3) {
      return reportDef?.exportConditions[2];
    }
    return {};
  };

  const selectChangeByDate = (value: number) => {
    if (params?.id) {
      loadViewDateData({ id: params.id, date: dateList[value] }).then();
    }
  };

  const group = (sourceFunc: any, count1: number, count2: number) => {
    let source = sourceFunc();
    let index = 0;
    let newArray : any[] = [];
    if (source) {
      let array = Array.from(source);
      while (index < array.length) {
        let size = index == 0 ? count1 : count2;
        newArray.push(array.slice(index, index += size));
      }
    }
    return newArray;
  };

  const dailRunTimeOne = () => {
    if (reportDef?.exportConditions.length) {
      // let _list: any[] = [];
      // reportDef?.exportConditions.map((value: any, index: number) => {
      //   if (value?.hourlyAdTimes.length) {
      //     _list.push(
      //       value?.hourlyAdTimes.map((_value: any, _index: number) => {
      //         return {
      //           key: `${index}000${_index}`,
      //           number: `${index}000${_index}`,
      //           startTime: _value.startTime,
      //           endTime: _value.endTime,
      //         };
      //       })
      //     );
      //   }
      // });
      // return [].concat.apply([], _list);
      if (reportDef?.exportConditions[0]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[0]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[0]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime,
              };
            }
          );
        }
      }
    }
  };

  const dailRunTimeTwo = () => {
    if (reportDef?.exportConditions.length > 1) {
      if (reportDef?.exportConditions[1]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[1]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[1]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime,
              };
            }
          );
        }
      }
    }
  };

  const dailRunTimeThree = () => {
    if (reportDef?.exportConditions.length > 2) {
      if (reportDef?.exportConditions[2]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[2]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[2]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime,
              };
            }
          );
        }
      }
    }
  };

  const getMaxConditionPageCount = () => {
    let firstConditionPageCount = group(dailRunTimeOne, 22, 28).length;
    let secondConditionPageCount = group(dailRunTimeTwo, 22, 28).length;
    let thirdConditionPageCount = group(dailRunTimeThree, 22, 28).length;
    let maxConditionPageCount = Math.max(firstConditionPageCount, secondConditionPageCount, thirdConditionPageCount);
    return maxConditionPageCount;
  };

  const timeSpecification = (key: number) => {
    if (
      reportDef?.exportConditions.length &&
      reportDef?.exportConditions.length >= key + 1
    ) {
      return {
        adEndTime: reportDef?.exportConditions[key]?.adEndTime,
        adStartTime: reportDef?.exportConditions[key]?.adStartTime,
        timeSpecification: reportDef?.exportConditions[key]?.timeSpecification,
      };
    }
  };

  return (
    <>
      <Breadcrumb className="noPrint">
        <Breadcrumb.Item>レポート</Breadcrumb.Item>
        <Breadcrumb.Item>レポート詳細</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard reportCard dashboardCard">
          <div className="cardHeader noPrint">
            <Button onClick={() => handleBack()}>
              <RollbackOutlined />
              戻る
            </Button>
            <Button onClick={() => handlePDF()}>
              <FilePdfOutlined />
              AVSとは？
            </Button>
            <ReactToPrint
              content={() => printArea}
              trigger={() => (
                <Button
                  type="primary"
                  key="primary"
                  onClick={() => window.print()}
                >
                  <Space>
                    PDF
                    <img src="/icon_down.png" height={16} width={16}/>
                  </Space>
                </Button>
              )}
            />
          </div>
          <div ref={(el) => (printArea = el)} className="cardBody">
            <div className="print_header">
              <img src="/logo_header.jpg" height="30px" />
            </div>
            {/*<div className="print_tail">*/}
            {/*  <p className={"page_number"}></p>*/}
            {/*</div>*/}
            <Collapse
              defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
              expandIconPosition="right"
            >
              <Panel
                header="（広告）基本情報"
                key="1"
                className={"print_header_second"}
              >
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      bordered
                      size={"middle"}
                      labelStyle={{ width: "220px" }}
                    >
                      <Descriptions.Item label="広告主名" span={3}>
                        {reportDef?.owner}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告内容" span={3}>
                        {reportDef?.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映期間" span={3}>
                        {moment(reportDef?.adStartDate).format("YYYY年M月D日")}
                        〜{moment(reportDef?.adEndDate).format("M月D日")}（
                        {moment(reportDef?.adEndDate).diff(
                          moment(reportDef?.adStartDate),
                          "days"
                        ) + 1}
                        日間）
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映料金" span={3}>
                        {!!reportDef?.adFee && <>{reportDef?.adFee}円</>}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <Panel header="（媒体）基本情報" key="2">
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      bordered
                      size={"middle"}
                      labelStyle={{ width: "220px" }}
                    >
                      <Descriptions.Item label="名称" span={3}>
                        {reportDef?.medias
                          .map((media: { name: string }) => media.name)
                          .join(",")}
                      </Descriptions.Item>
                      <Descriptions.Item label="所在地" span={3}>
                        {reportDef?.medias
                          .map((media: { location: string }) => media.location)
                          .join(",")}
                      </Descriptions.Item>
                      <Descriptions.Item label="運営時間" span={3}>
                        {reportDef?.medias
                          .map(
                            (media: {
                              opStartTime: string;
                              opEndTime: string;
                            }) => media.opStartTime + "～" + media.opEndTime
                          )
                          .join(",")}
                      </Descriptions.Item>
                      <Descriptions.Item label="音声" span={3}>
                        {reportDef?.medias
                          .map(
                            (media: {
                              hasAudio: boolean;
                              audioComment: string;
                            }) =>
                              (media.hasAudio ? "音声あり" : "音声なし") +
                              (media.audioComment != null
                                ? "　※" + media.audioComment
                                : "")
                          )
                          .join(",")}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <Panel className={"fillBlank"} header="レポート出力条件" key="3">
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      title={"出力条件1"}
                      bordered
                      size={"small"}
                      labelStyle={{ width: "220px" }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsFirst()?.timeSpecification != 2 && (
                          <>{getExportConditionsFirst()?.materialName || ""}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay() || ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="素材尺" span={3}>
                        {getExportConditionsFirst()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsFirst()?.materialScale
                              ? getExportConditionsFirst()?.materialScale + "秒"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="毎時放映回数" span={3}>
                        {getExportConditionsFirst()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsFirst()?.adCountPerHour
                              ? getExportConditionsFirst()?.adCountPerHour +
                                "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（日）" span={3}>
                        {getExportConditionsFirst()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsFirst()?.adCountPerDay
                              ? getExportConditionsFirst()?.adCountPerDay + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（期間）" span={3}>
                        {getExportConditionsFirst()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsFirst()?.adCountOfPeriod
                              ? getExportConditionsFirst()?.adCountOfPeriod +
                                "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  align="bottom"
                  style={{ paddingTop: "16px" }}
                >
                  <Col span={24}>
                    <Descriptions
                      title={"出力条件2"}
                      bordered
                      size={"small"}
                      labelStyle={{ width: "220px" }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsSec()?.timeSpecification != 2 && (
                          <>{getExportConditionsSec()?.materialName || ""}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay2() || ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="素材尺" span={3}>
                        {getExportConditionsSec()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsSec()?.materialScale
                              ? getExportConditionsSec()?.materialScale + "秒"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="毎時放映回数" span={3}>
                        {getExportConditionsSec()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsSec()?.adCountPerHour
                              ? getExportConditionsSec()?.adCountPerHour + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（日）" span={3}>
                        {getExportConditionsSec()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsSec()?.adCountPerDay
                              ? getExportConditionsSec()?.adCountPerDay + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（期間）" span={3}>
                        {getExportConditionsSec()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsSec()?.adCountOfPeriod
                              ? getExportConditionsSec()?.adCountOfPeriod + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  align="bottom"
                  style={{ paddingTop: "16px" }}
                >
                  <Col span={24}>
                    <Descriptions
                      title={"出力条件3"}
                      bordered
                      size={"small"}
                      labelStyle={{ width: "220px" }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsThr()?.timeSpecification != 2 && (
                          <>{getExportConditionsThr()?.materialName || ""}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay3() || ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="素材尺" span={3}>
                        {getExportConditionsThr()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsThr()?.materialScale
                              ? getExportConditionsThr()?.materialScale + "秒"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="毎時放映回数" span={3}>
                        {getExportConditionsThr()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsThr()?.adCountPerHour
                              ? getExportConditionsThr()?.adCountPerHour + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（日）" span={3}>
                        {getExportConditionsThr()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsThr()?.adCountPerDay
                              ? getExportConditionsThr()?.adCountPerDay + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="放映回数（期間）" span={3}>
                        {getExportConditionsThr()?.timeSpecification != 2 && (
                          <>
                            {getExportConditionsThr()?.adCountOfPeriod
                              ? getExportConditionsThr()?.adCountOfPeriod + "回"
                              : ""}
                          </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <div className="print_tail" style={{ left: 0, top: "1633px" }}>
                <p className={"page_number"}>1/{getMaxConditionPageCount()+2}</p>
              </div>
              <Panel
                header={
                  "広告期間全体（" +
                  moment(reportDef?.adStartDate).format("M月D日") +
                  "〜" +
                  moment(reportDef?.adEndDate).format("M月D日") +
                  "）"
                }
                key="4"
                className={"print_header_second"}
              >
                <AdPeriod printTotalPage={getMaxConditionPageCount()+2} />
              </Panel>
              <Panel className={"noPrint"} header={getDateTitle()} key="5">
                <p>
                  <Select
                    style={{ width: "200px" }}
                    defaultValue={0}
                    onChange={selectChangeByDate}
                  >
                    {dateList.map((value, index) => (
                      <Option value={index} key={index}>{`${moment(
                        value
                      ).format("M月D日")}`}</Option>
                    ))}
                  </Select>
                </p>
                <ReportDate />
              </Panel>
              <Panel header="出力条件（放映タイミング）詳細" key="6">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    {group(dailRunTimeOne, 22, 28).length == 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return "【出力条件1】";
                          }}
                          rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeOne()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(0)?.timeSpecification === 1
                                ? "放映時間: " +
                                timeSpecification(0)?.adStartTime +
                                "-" +
                                timeSpecification(0)?.adEndTime
                                : timeSpecification(0)?.timeSpecification === 0
                                ? "放映時間: 終日"
                                : timeSpecification(0)?.timeSpecification === 2
                                  ? "放映時間: 媒体運営時間全て"
                                  : "放映時間: "
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                    {
                      group(dailRunTimeOne, 22, 28).map((data, i) => {
                        return (
                          <div>
                            <div className={i == 0 ? "exportConditionTableSpaceHide"
                              : i > 1 ? "exportConditionTableSpace2" : "exportConditionTableSpace1"}></div>
                            <Table
                              bordered={true}
                              title={() => {
                                return "【出力条件1】";
                              }}
                              rowKey="id"
                              // columns={columns}
                              dataSource={data}
                              className="exportConditionTable"
                              size="middle"
                              pagination={false}
                            >
                              <ColumnGroup
                                title={
                                  timeSpecification(0)?.timeSpecification === 1
                                    ? "放映時間: " +
                                    timeSpecification(0)?.adStartTime +
                                    "-" +
                                    timeSpecification(0)?.adEndTime
                                    : timeSpecification(0)?.timeSpecification === 0
                                    ? "放映時間: 終日"
                                    : timeSpecification(0)?.timeSpecification === 2
                                      ? "放映時間: 媒体運営時間全て"
                                      : "放映時間: "
                                }
                              >
                                <Column
                                  title="開始時間(分秒)"
                                  dataIndex="startTime"
                                  key="startTime"
                                />
                                <Column
                                  title="終了時間(分秒)"
                                  dataIndex="endTime"
                                  key="endTime"
                                />
                              </ColumnGroup>
                            </Table>
                          </div>
                        )
                      })
                    }
                    </>
                  </Col>
                  <Col span={8}>
                    { group(dailRunTimeTwo, 22, 28).length == 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return "【出力条件2】";
                          }}
                          rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeTwo()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(1)?.timeSpecification === 1
                                ? "放映時間: " +
                                timeSpecification(1)?.adStartTime +
                                "-" +
                                timeSpecification(1)?.adEndTime
                                : timeSpecification(1)?.timeSpecification === 0
                                ? "放映時間: 終日"
                                : timeSpecification(1)?.timeSpecification === 2
                                  ? "放映時間: 媒体運営時間全て"
                                  : "放映時間: "
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                      {
                        group(dailRunTimeTwo, 22, 28).map((data, i) => {
                          return (
                            <div>
                              <div className={i == 0 ? "exportConditionTableSpaceHide"
                                : i > 1 ? "exportConditionTableSpace2" : "exportConditionTableSpace1"}></div>
                              <Table
                                bordered={true}
                                title={() => {
                                  return "【出力条件2】";
                                }}
                                rowKey="id"
                                // columns={columns}
                                dataSource={data}
                                className="exportConditionTable"
                                size="middle"
                                pagination={false}
                              >
                                <ColumnGroup
                                  title={
                                    timeSpecification(1)?.timeSpecification === 1
                                      ? "放映時間: " +
                                      timeSpecification(1)?.adStartTime +
                                      "-" +
                                      timeSpecification(1)?.adEndTime
                                      : timeSpecification(1)?.timeSpecification === 0
                                      ? "放映時間: 終日"
                                      : timeSpecification(1)?.timeSpecification === 2
                                        ? "放映時間: 媒体運営時間全て"
                                        : "放映時間: "
                                  }
                                >
                                  <Column
                                    title="開始時間(分秒)"
                                    dataIndex="startTime"
                                    key="startTime"
                                  />
                                  <Column
                                    title="終了時間(分秒)"
                                    dataIndex="endTime"
                                    key="endTime"
                                  />
                                </ColumnGroup>
                              </Table>
                            </div>
                          )
                        })
                      }
                    </>
                  </Col>
                  <Col span={8}>
                    { group(dailRunTimeThree, 22, 28).length == 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return "【出力条件3】";
                          }}
                          rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeThree()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(2)?.timeSpecification === 1
                                ? "放映時間: " +
                                timeSpecification(2)?.adStartTime +
                                "-" +
                                timeSpecification(2)?.adEndTime
                                : timeSpecification(2)?.timeSpecification === 0
                                ? "放映時間: 終日"
                                : timeSpecification(2)?.timeSpecification === 2
                                  ? "放映時間: 媒体運営時間全て"
                                  : "放映時間: "
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                      {
                        group(dailRunTimeThree, 22, 28).map((data, i) => {
                          return (
                            <div>
                              <div className={i == 0 ? "exportConditionTableSpaceHide"
                                : i > 1 ? "exportConditionTableSpace2" : "exportConditionTableSpace1"}></div>
                              <Table
                                bordered={true}
                                title={() => {
                                  return "【出力条件3】";
                                }}
                                rowKey="id"
                                // columns={columns}
                                dataSource={data}
                                className="exportConditionTable"
                                size="middle"
                                pagination={false}
                              >
                                <ColumnGroup
                                  title={
                                    timeSpecification(2)?.timeSpecification === 1
                                      ? "放映時間: " +
                                      timeSpecification(2)?.adStartTime +
                                      "-" +
                                      timeSpecification(2)?.adEndTime
                                      : timeSpecification(2)?.timeSpecification === 0
                                      ? "放映時間: 終日"
                                      : timeSpecification(2)?.timeSpecification === 2
                                        ? "放映時間: 媒体運営時間全て"
                                        : "放映時間: "
                                  }
                                >
                                  <Column
                                    title="開始時間(分秒)"
                                    dataIndex="startTime"
                                    key="startTime"
                                  />
                                  <Column
                                    title="終了時間(分秒)"
                                    dataIndex="endTime"
                                    key="endTime"
                                  />
                                </ColumnGroup>
                              </Table>
                            </div>
                          )
                        })
                      }
                    </>
                  </Col>
                </Row>
                {new Array(getMaxConditionPageCount()).fill(1).map((v, i) => (
                  <div className="print_tail" style={{ left: 0, top: 4992 + (i * 1679) }}>
                    <p className={"page_number"}>{i + 3}/{getMaxConditionPageCount()+2}</p>
                  </div>
                ))}
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ReportDetails);
