import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../view/access/login';
import Layouts from '../view/layouts/layouts';
import {
	PATH_ACCOUNT,
	PATH_ACCOUNT_ADD,
	PATH_ACCOUNT_UPDATE,
	PATH_AGENCY,
	PATH_AGENCY_ADD,
	PATH_AGENCY_UPDATE,
	PATH_DASHBOARD_MANAGE,
	PATH_DASHBOARD_ACCOUNT,
	PATH_LOGIN,
	PATH_MEDIA,
	PATH_MEDIA_ADD,
	PATH_MEDIA_UPDATE,
	PATH_PROFILE,
	PATH_REPORT,
	PATH_SETTING,
	PATH_DASHBOARD_MEDIA,
	PATH_REPORT_DETAILS,
	PATH_REPORT_ADD,
	PATH_REPORT_UPDATE,
	PATH_REPORT_COPY,
} from './router-path';
import DashboardManage from '../view/dashboard/manage';
import DashboardAccount from '../view/dashboard/account';
import DashboardMedia from '../view/dashboard/media';
import MediaEdit from '../view/account/media/media-edit';
import MediaList from '../view/account/media/meida-list';
import AgencyList from '../view/account/agency/agency-list';
import AgencyEdit from '../view/account/agency/agency-edit';
import AccountList from '../view/account/account/account-list';
import AccountEdit from '../view/account/account/account-edit';
import Profile from '../view/user/profile';
import Report from '../view/report/report-list';
import NotFound from './NotFound';
import AuthProvider from './RequireAuth';
import { AliveScope } from 'react-activation';
import ReportDetails from '../view/report/report-details';
import ReportEdit from '../view/report/report-edit';
import Setting from '../view/user/setting/setting-list';

export const RootRouters = () => {
	return <BrowserRouter>
		<AliveScope>
			<Routes>
				<Route path={'/'} element={
					<AuthProvider>
						<Layouts/>
					</AuthProvider>
				}>
					<Route index element={<NotFound/>}/>
					<Route path={PATH_ACCOUNT} element={<AccountList/>}/>
					<Route path={PATH_ACCOUNT_ADD} element={<AccountEdit/>}/>
					<Route path={`${PATH_ACCOUNT_UPDATE}/:id`} element={<AccountEdit/>}/>
					<Route path={PATH_MEDIA} element={<MediaList/>}/>
					<Route path={PATH_MEDIA_ADD} element={<MediaEdit/>}/>
					<Route path={`${PATH_MEDIA_UPDATE}/:id`} element={<MediaEdit/>}/>
					<Route path={PATH_AGENCY} element={<AgencyList/>}/>
					<Route path={PATH_AGENCY_ADD} element={<AgencyEdit/>}/>
					<Route path={`${PATH_AGENCY_UPDATE}/:id`} element={<AgencyEdit/>}/>
					<Route path={PATH_PROFILE} element={<Profile/>}/>
					<Route path={PATH_DASHBOARD_MANAGE} element={<DashboardManage/>}/>
					<Route path={PATH_DASHBOARD_ACCOUNT} element={<DashboardAccount/>}/>
					<Route path={PATH_DASHBOARD_MEDIA} element={<DashboardMedia/>}/>
					<Route path={PATH_SETTING} element={<Setting/>}/>
					<Route path={PATH_REPORT} element={<Report/>}/>
					<Route path={PATH_REPORT_ADD} element={<ReportEdit/>}/>
					<Route path={`${PATH_REPORT_UPDATE}/:id`} element={<ReportEdit/>}/>
					<Route path={`${PATH_REPORT_COPY}/:id`} element={<ReportEdit/>}/>
					<Route path={`${PATH_REPORT_DETAILS}/:id`} element={<ReportDetails/>}/>
					<Route path="*" element={<NotFound/>}/>
				</Route>
				<Route path={PATH_LOGIN} element={<Login/>}/>
			</Routes>
		</AliveScope>
	</BrowserRouter>;
};
