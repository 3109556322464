import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LabelLayout } from "echarts/features";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

interface Props {
  data?: Array<{ name: string; value: number }>;
  title?: string;
}
// 性別構成用にEchartsPieをコピーして色だけ変更
const EchartsPieForGenderRate = (props: Props) => {
  const { title } = props;
  const { data } = props;

  const getOption = () => {
    return {
      color: [
        '#00c4cd',
        '#e66665'
      ],
      title: [
        {
          text: title,
          left: "center",
        },
      ],
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  const echartsEl = useRef(null);

  return (
      <div className="echartsBox">
        <ReactEcharts
            option={getOption()}
            style={{ width: "320px", height: " 350px" }}
            ref={echartsEl}
        />
      </div>
  );
};

export default observer(EchartsPieForGenderRate);
