import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, DatePicker, Form } from 'antd';
import moment from 'moment';
import { DATE } from '../../../../constants';
import { DashboardContext } from '../../store/dashboard-store';

let timeout: any;

const AccountForm = () => {

	const [form] = Form.useForm();

	const {
		summaryStore: {loadData},
		mediaSelListStore: {loadData: loadMediaListData},
	} = useContext(DashboardContext);

	const today: string = moment().format('YYYYMMDD');

	useEffect(() => {
		loadMediaListData().then();
		loadSummaryData(null, today);
	}, [loadMediaListData, today]);

	useEffect(() => {
		return () => clearTimerLoad();
	}, []);

	const clearTimerLoad = () => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	};

	const startTimerLoad = (mediaIds: any, date: string) => {
		clearTimerLoad();
		timeout = setTimeout(() => {
			loadSummaryData(mediaIds, date);
		}, 15 * 1000);
	};

	const loadSummaryData = (mediaIds: any, date: string) => {
		loadData({mediaIds: mediaIds, date: date}).then(({success}) => {
			if (success) {
				if (date === today) {
					startTimerLoad(mediaIds, date);
				} else {
					clearTimerLoad();
				}
			}
		});
	};

	const onFinish = async (values: any) => {
		const date = moment(values.date).format('YYYYMMDD');
		if (date !== today) {
			clearTimerLoad();
		}
		loadSummaryData(null, date);
	};

	return <>
		<Form form={form}
		      onFinish={onFinish}
		      className='form'
		      layout="inline"
		>
			<Form.Item name="date" initialValue={moment(moment().format(DATE))}
			           rules={[{required: true, message: '媒体を選択してください'}]}>
				<DatePicker allowClear={false}/>
			</Form.Item>
			<Form.Item>
				<Button type='primary' onClick={() => form.submit()}>検索</Button>
			</Form.Item>
		</Form>
	</>;
};

export default observer(AccountForm);
