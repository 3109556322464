export class NumberUtils {
  public static toThousands(
    num: number = 0,
    chart: string = ",",
    length: number = 3
  ) {
    let result = "";
    let nums = num.toString().split(".");
    let int = nums[0];
    let decmial = nums[1] ? "." + nums[1] : "";
    let index = 0;
    for (let n = int.length - 1; n >= 0; n--) {
      index++;
      result = int[n] + result;
      if (index % length === 0 && n !== 0) {
        result = chart + result;
      }
    }
    return result + decmial;
  }
  public static fixPoint(num: number = 0, reserve: number = 1) {
    let reserveNum = reserve * 10;
    return Math.round(num * reserveNum) / reserveNum;
  }
}
