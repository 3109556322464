import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

interface Pros {
	value?: string;
	onChange?: (value: string) => void;
}

const {Option} = Select;

// @ts-ignore
const arrayForOptionMin = [...new Array(60).keys()];
// @ts-ignore
const arrayForOptionSec = [...new Array(60).keys()];

function TimeMMss({value, onChange}: Pros) {
	const [mm, mmChange] = useState<string>('');
	const [ss, ssChange] = useState<string>('');

	const getTimes = (value: string) => {
		if (value) {
			const times = value.split(':');
			if (times.length === 2) {
				return times;
			}
		}
		return ['', ''];
	};

	// 检查更新 小时
	useEffect(() => {
		if (value && value.length > 1) {
			const [_mm] = getTimes(value);
			if (_mm !== mm) {
				mmChange(_mm);
			}
		}
	}, [mm, value, mmChange]);

	// 检查更新 分钟
	useEffect(() => {
		if (value && value.length > 1) {
			const [_, _ss] = getTimes(value);
			if (_ss !== ss) {
				ssChange(_ss);
			}
		}
	}, [ss, value, ssChange]);

	const onChangeMM = (value: string) => {
		mmChange(value);
		if (onChange) {
			onChange(`${value}:${ss || ''}`);
		}
	};
	const onChangeSS = (value: string) => {
		ssChange(value);
		if (onChange) {
			onChange(`${mm || ''}:${value}`);
		}
	};

	return (
		<>
			<Select
				style={{width: 60}}
				value={mm}
				onChange={onChangeMM}
			>
				{arrayForOptionMin.map((value) => {
					const _value = value < 10 ? `0${value}` : value;
					return <Option value={_value}>{_value}</Option>;
				})}
			</Select>
			<span> : </span>
			<Select
				style={{width: 60}}
				value={ss}
				onChange={onChangeSS}
			>
				{arrayForOptionSec.map((value) => {
					const _value = value < 10 ? `0${value}` : value;
					return <Option value={_value}>{_value}</Option>;
				})}
			</Select>
		</>
	);
}

export default TimeMMss;
