import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, BarChart, CanvasRenderer]);

interface Props {
	data?: {
		xAxis: Array<string>;
		series: Array<any>;
	};
}

const EchartsBar = (props: Props) => {
	const {data} = props;

	const getOption = () => {
		return {
			color: [
				'#00c4cd',
				'#75bd7a',
				'#fdaf43',
				'#e66665',
				'#b452c5',
				'#42c1f8',
				'#dddee0',
				'#9ea1a0'
			],
			colorBy: 'xAxis',
			xAxis: {
				type : 'category',
				data: data?.xAxis || [],
				axisLabel:{
					interval:0,//横轴信息全部显示
				},
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					interval: 0,
				},
			},
			grid: {
				left: 80,
			},
			tooltip: {
				trigger: 'axis',
			},
			series: [
				{
					data: data?.series || [],
					type: 'bar',
				},
			],
		};
	};

	const echartsEl = useRef(null);

	return (
		<div className="echartsBox">
			<ReactEcharts
				option={getOption()}
				style={{width: '350px', height: ' 350px'}}
				ref={echartsEl}
			/>
		</div>
	);
};

export default observer(EchartsBar);
