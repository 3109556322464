import { useContext } from 'react';
import { AuthContext } from '../store/auth-store';
import { RoleType } from '../store/user.model';
import { PATH_DASHBOARD_ACCOUNT, PATH_DASHBOARD_MANAGE, PATH_REPORT } from '../router/router-path';
import { useLocation, useNavigate } from 'react-router-dom';

export const useAuth = () => {
	const authStore = useContext(AuthContext);
	return authStore.isAuth;
};

export const useRoleType = (): RoleType => {
	const authStore = useContext(AuthContext);
	return authStore.role !== undefined ? authStore.role : RoleType.USER;
};

export const useIndexPath = (): (roleType?: RoleType) => string => {
	return (roleType?: RoleType) => {
		switch (roleType) {
			case RoleType.ADMIN:
				return PATH_DASHBOARD_MANAGE;
			case RoleType.AD_MEDIA:
				return PATH_DASHBOARD_ACCOUNT;
			default:
				return PATH_REPORT;
		}
	};
};

export const useRoleBack = (): (admin: () => void) => void => {
	const navigate = useNavigate();
	const location = useLocation();
	const indexPath = useIndexPath();
	const roleType = useRoleType();
	return (admin: () => void) => {
		console.log(roleType)
		if (roleType === RoleType.ADMIN) {
			admin();
		} else if (location?.state?.from) {
			navigate(location?.state?.from.pathname);
		} else {
			navigate(indexPath(roleType));
		}
	};
};
