import { ProColumns } from "@ant-design/pro-table/lib/typing";
import { Space } from "antd";
import React from "react";
import { AgencyUserModel } from "../../store/model/agency-user.model";
import { ViewModalStore } from "mobx-template";
import Popconfirm from "antd/es/popconfirm";

const getColumns = (
  modalStore: ViewModalStore,
  delUserStore: any,
  detailStore: any
): ProColumns<AgencyUserModel>[] => {
  const { submit } = delUserStore;
  return [
    {
      title: "ユーザID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 100,
    },
    {
      title: "ユーザ名",
      dataIndex: "username",
      key: "username",
      align: "left",
      ellipsis: true,
    },
    {
      title: "部署",
      dataIndex: "adminDept",
      key: "adminDept",
      align: "left",
      ellipsis: true,
    },
    {
      title: "電話番号",
      dataIndex: "telNo",
      key: "telNo",
      align: "left",
      width: 160,
    },
    {
      title: "メールアドレス",
      dataIndex: "adminEmail",
      key: "adminEmail",
      align: "left",
      width: 180,
    },
    {
      title: "パスワード",
      dataIndex: "password",
      key: "password",
      align: "left",
      width: 160,
    },
    {
      title: "操作",
      dataIndex: "others",
      key: "others",
      align: "center",
      width: 120,
      render: (_, record: AgencyUserModel) => {
        return (
          <Space size="small">
            <a key="editable" onClick={() => modalStore.openModal(record)}>
              更新
            </a>
            <Popconfirm
              placement="leftBottom"
              title="本当に削除してもよろしいでしょうか"
              okText="削除"
              cancelText="キャンセル"
              onConfirm={() => {
                submit({ ...record, agencyID: detailStore.data.id });
              }}
            >
              <a key="editable">削除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
};

export default getColumns;
