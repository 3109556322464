import React, { useContext, useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { DashboardContext } from "../../store/dashboard-store";
import { NumberUtils } from "../../../../utils/number-utils";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

interface Props {
  showTotal: boolean;
}

const EchartsStackedLine = (props: Props) => {
  const { showTotal } = props;
  const {
    summaryStore: { data },
    dataZoom24,
  } = useContext(DashboardContext);

  let onEvents = {
    dataZoom: (event: any) => {
      if (event.batch) {
        dataZoom24[0] = {
          type: "inside",
          start: event.batch[0].start,
          end: event.batch[0].end,
        };
        dataZoom24[1] = {
          start: event.batch[0].start,
          end: event.batch[0].end,
          labelFormatter: () => {
            return "";
          },
        };
      } else {
        dataZoom24[0] = {
          type: "inside",
          start: event.start,
          end: event.end,
        };
        dataZoom24[1] = {
          start: event.start,
          end: event.end,
          labelFormatter: () => {
            return "";
          },
        };
      }
    },
  };

  let tmp: Array<any> = [];
  for (let i = 0; i < data?.realtimeTrendByMedia24Hours?.legends.length; i++) {
    const name = data?.realtimeTrendByMedia24Hours?.legends[i];
    const datas = data?.realtimeTrendByMedia24Hours?.series[name];
    const temp = {
      name: name,
      type: "line",
      symbol: "none",
      lineStyle: {
        width: 1,
      },
      data: datas,
    };
    tmp.push(temp);
  }

  if (showTotal) {
    let totalData = [];

    for (let i = 0; i < data?.realtimeTrendByMedia24Hours?.xAxis.length; i++) {
      let total = 0;
      for (
        let j = 0;
        j < data?.realtimeTrendByMedia24Hours?.legends.length;
        j++
      ) {
        const name = data?.realtimeTrendByMedia24Hours?.legends[j];
        const datas = data?.realtimeTrendByMedia24Hours?.series[name];
        total += parseFloat(datas[i]);
      }
      totalData.push(total);
    }

    data?.realtimeTrendByMedia24Hours?.legends.push("Total");

    tmp.unshift({
      name: "Total",
      type: "line",
      symbol: "none",
      lineStyle: {
        width: 1,
      },
      data: totalData,
    });
  }

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: Array<any>, ticket: Object, callback: Function) => {
          let headerStr = params[0].axisValue + "<br />";
          let tipString = "";
          let indexColor;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="min-width: 200px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;margin-right:10px;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${
              params[i].seriesName
            }</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
          }
          tipString = headerStr + tipString;
          return tipString;
        },
      },
      legend: {
        icon: "circle",
        data: data?.realtimeTrendByMedia24Hours?.legends,
        padding: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top: "70px",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: data?.realtimeTrendByMedia24Hours?.xAxis,
      },
      yAxis: {
        type: "value",
      },
      dataZoom: dataZoom24,
      series: tmp,
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "1160px", height: " 460px" }}
        ref={echartsEl}
        showLoading={!data?.realtimeTrendByMedia24Hours}
        onEvents={onEvents}
      />
    </div>
  );
};

export default observer(EchartsStackedLine);
