import React, { useContext, useEffect } from "react";
import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_ACCOUNT } from "../../../../../router/router-path";
import { AccountContext } from "../../store/account-store";
import { useAliveNotify } from "../../../../../core/keep-alive/use-keep-alive";
import { useFormInit } from "../../../../../core/form";
import { useRoleBack } from "../../../../../hooks/use-auth";

const AccountEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const roleBack = useRoleBack();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const {
    detailStore: { data, loadData, isBusy: loading },
    editStore: { submit, isBusy },
    delStore: { isBusy: delLoading },
  } = useContext(AccountContext);

  useEffect(() => {
    if (params?.id) {
      loadData({ id: params?.id }).then();
    }
  }, [loadData, params?.id]);

  useFormInit(form, data);

  const onFinish = async (values: any) => {
    console.log(values);
    const { success } = await submit(values);
    if (success) {
      roleBack(() => {
        aliveNotify();
        navigate(PATH_ACCOUNT);
      });
    }
  };

  const handleBack = () => {
    roleBack(() => {
      navigate(PATH_ACCOUNT);
    });
  };

  return (
    <Spin tip="読み込み..." spinning={loading || delLoading}>
      <Form form={form} {...layout} onFinish={onFinish} className="form">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="id" label="アカウントID">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="username"
              label="アカウント名"
              rules={[
                { required: true, message: "アカウント名を入力してください" },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="アカウント名を入力してください"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="パスワード"
              rules={[
                { required: true, message: "パスワードを入力してください" },
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                maxLength={50}
                placeholder="パスワードを入力してください"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="pswAgain"
              label="パスワード(確認)"
              rules={[
                { required: true, message: "パスワードを入力してください" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("入力パスワードと一致していません!");
                  },
                }),
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                maxLength={50}
                placeholder="パスワードを入力してください"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="address" label="住所">
              <Input maxLength={255} placeholder="住所を入力してください" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="telNo" label="電話番号">
              <Input maxLength={20} placeholder="電話番号を入力してください" />
            </Form.Item>
            <p style={{ position: "absolute", top: "32px", right: "12px" }}>
              ※半角数字(ハイフン無し)
            </p>
          </Col>
        </Row>

        <Divider orientation="left">管理者情報</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="adminName"
              label="管理者名"
              rules={[
                { required: true, message: "管理者名を入力してください" },
              ]}
            >
              <Input maxLength={50} placeholder="管理者名を入力してください" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="adminNameKana"
              label="管理者名(カナ)"
              rules={[
                { required: true, message: "管理者名(カナ)を入力してください" },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="管理者名(カナ)を入力してください"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="adminDept" label="部署名">
              <Input maxLength={50} placeholder="部署名を入力してください" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="adminEmergencyTelNo"
              label="緊急連絡先"
              rules={[
                { required: true, message: "緊急連絡先を入力してください" },
              ]}
            >
              <Input
                maxLength={20}
                placeholder="緊急連絡先を入力してください"
              />
            </Form.Item>
            <p style={{ position: "absolute", top: "32px", right: "12px" }}>
              ※半角数字(ハイフン無し)
            </p>
          </Col>
          <Col span={12}>
            <Form.Item
              name="adminEmail"
              label="メールアドレス"
              rules={[
                { required: true, message: "メールアドレスを入力してください" },
                { type: "email" },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="メールアドレスを入力してください"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => handleBack()}>キャンセル</Button>
              <Button type="primary" loading={isBusy} htmlType="submit">
                {params.id ? "変更" : "登録"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(AccountEditForm);
