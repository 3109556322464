import { makeAutoObservable } from 'mobx';
import React from 'react';
import { fromObjStore } from 'mobx-template';
import { fetchGet } from '../utils/request';
import { RoleType, UserModel } from './user.model';

export class AuthStore {

	ROLE_ID_KEY = 'role_id_key';

	private _role: RoleType | undefined;

	constructor() {
		const role = localStorage.getItem(this.ROLE_ID_KEY);
		this._role = role !== undefined ? Number(role) : undefined;
		makeAutoObservable(this);
	}

	userStore = fromObjStore<UserModel>(
		() => fetchGet(`/api/v1/common/userinfo`),
		{
			successCallback: (data: UserModel) => {
				this.auth = data.role?.id;
			},
		},
	);

	set user(user: UserModel) {
		this.userStore.setData(user);
		this.auth = user.role?.id;
	}

	get role(): RoleType | undefined {
		return this._role;
	}

	set auth(id: RoleType | undefined) {
		if (id) {
			localStorage.setItem(this.ROLE_ID_KEY, id.toString());
		} else {
			localStorage.removeItem(this.ROLE_ID_KEY);
		}
		this._role = id;
	}

	get isAuth() {
		return this._role !== undefined && this._role !== null;
	}

	removeAuth() {
		this._role = undefined;
	}

}

export const AuthContext = React.createContext<AuthStore>(new AuthStore());

