import { useLayoutEffect } from 'react';
import { message } from 'antd';
import { ConfigService, UseResult } from 'mobx-template';
import EventBus from '../core/event-bus';
import { EVENT_GO_TO_LOGIN_KEY } from '../constants';

export const useConfigSetUp = () => {

	useLayoutEffect(() => {
		ConfigService.setup({
			useSystemErrorMessage: true,
			systemErrorMessage: 'システムの例外は、管理者に連絡してください！',
			use401Message: true,
			message401: 'ログインの有効期限が切れています。もう一度ログインしてください！',
			handleHttpResult: (resBody: any): UseResult<any> => {
				const {success, errorCode, errorMessage, payload, count} = resBody || {};
				return {success, errorCode, errorMessage, data: payload, total: count || 0};
			},
			handleHttpErrorResult: (resBody: any): UseResult<any> => {
				const {success, errorCode, errorMessage} = resBody || {};
				return {success, errorCode, errorMessage};
			},
			showErrorMessage: ({message: errorMessage, status}) => {
				if (status === 401) {
					message.warn(errorMessage);
				} else {
					message.error(errorMessage);
				}
			},
			showSuccessMessage: (errorMessage: string) => {
				message.success(errorMessage);
			},
			handle401: () => {
				EventBus.emit(EVENT_GO_TO_LOGIN_KEY);
			},
		});
	});
};
