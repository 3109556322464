import KeepAlive, { KeepAliveProps, useAliveController } from 'react-activation';
import React, { ReactNode, ReactNodeArray, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props extends KeepAliveProps {
	children: ReactNode | ReactNodeArray;
	onlyOne?: boolean;
}

export const MyKeepAlive = ({onlyOne, name, children, ...attr}: Props) => {
	const location = useLocation();
	const _name = name || location.pathname;
	return <KeepAlive name={_name} {...attr}>
		<AliveController onlyOne={onlyOne}>
			{children}
		</AliveController>
	</KeepAlive>;
};

const AliveController = ({onlyOne, children}: { children: ReactNode | ReactNodeArray; onlyOne?: boolean; }) => {
	const {clear} = useAliveController();

	useEffect(() => {
		if (onlyOne) {
			clear().then();
		}
	}, [onlyOne, clear]);

	return <>{children}</>;
};
