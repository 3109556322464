import React, { useContext } from "react";
import { Button, Form, Input, Modal } from "antd";
import { AgencyUserContext } from "../../store/agency-user-store";
import { useFormInit } from "../../../../../core/form";
import { observer } from "mobx-react-lite";
import { useModalCloseEffect } from "mobx-template";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AgencyUserModal = () => {
  const [form] = Form.useForm();

  const { modalStore, editStore, listStore } = useContext(AgencyUserContext);

  const { isBusy, submit } = editStore;

  const { visible, data, closeModal, isUpdate } = modalStore;

  useFormInit(form, data);

  const actionText = isUpdate ? "変更" : "登録";

  useModalCloseEffect(() => {
    form.resetFields();
  }, modalStore);

  const onFinish = async (values: any) => {
    const { success } = await submit(values);
    if (success) {
      listStore.reload();
      closeModal();
    }
  };

  return (
    <Modal
      title={actionText}
      visible={visible}
      onCancel={closeModal}
      getContainer={false}
      centered
      forceRender
      footer={[
        <Button key="cancel" onClick={() => closeModal()}>
          キャンセル
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={() => form.submit()}
          loading={isBusy}
        >
          {actionText}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="agencyUser"
        {...formItemLayout}
        onFinish={onFinish}
        className="form"
      >
        <Form.Item name="id" label="ユーザID">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="username"
          label="ユーザ名"
          rules={[{ required: true, message: "ユーザ名を入力してください" }]}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item name="adminDept" label="部署名" rules={[{ required: true, message: "部署名を入力してください" }]}>
          <Input maxLength={50} placeholder="部署名を入力してください" />
        </Form.Item>
        <Form.Item name="telNo" label="電話番号" rules={[{ required: true, message: "電話番号を入力してください" }]}>
          <Input maxLength={20} placeholder="電話番号を入力してください" />
        </Form.Item>
        <p style={{ position: "absolute", top: "278px", right: "64px" }}>
          ※半角数字(ハイフン無し)
        </p>
        <Form.Item
          name="adminEmail"
          label="メールアドレス"
          rules={[{ type: "email", required: true, message: "メールアドレスを入力してください" }]}
        >
          <Input
            maxLength={50}
            placeholder="メールアドレスを入力してください"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="パスワード"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
        >
          <Input.Password
            autoComplete="new-password"
            maxLength={50}
            placeholder="パスワードを入力してください"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(AgencyUserModal);
