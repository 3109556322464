import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Card, Col, Row, Spin } from "antd";
import DashboardGender from "../components/DashboardGender";
import AccountForm from "./components/AccountForm";
import EchartsStackedLine from "./components/EchartsStackedLine";
import EchartsStackedArea from "../components/EchartsStackedArea";
import { DashboardContext } from "../store/dashboard-store";
import EchartsPie from "../components/EchartsPie";
import EchartsBar from "../components/EchartsBar";
import EchartsRadar from "../components/EchartsRadar";
import EchartsPieForGenderRate from "../components/EchartsPieForGenderRate";

const AccountMain = () => {
  const {
    summaryStore: { data: summaryData, isBusy: loading },
  } = useContext(DashboardContext);

  const getGenderProportion = () => {
    return [
      {
        name: "男性",
        value: summaryData?.genderDaily?.male || 0,
      },
      {
        name: "女性",
        value: summaryData?.genderDaily?.female || 0,
      },
    ];
  };

  const getAgeProportionMale = () => {
    return [
      { name: "0-3", value: summaryData?.ageProportionMale?.age0_3 || 0 },
      { name: "4-12", value: summaryData?.ageProportionMale?.age4_12 || 0 },
      { name: "13-19", value: summaryData?.ageProportionMale?.age13_19 || 0 },
      { name: "20-34", value: summaryData?.ageProportionMale?.age20_34 || 0 },
      { name: "35-49", value: summaryData?.ageProportionMale?.age35_49 || 0 },
      { name: "50-69", value: summaryData?.ageProportionMale?.age50_69 || 0 },
      { name: "70-", value: summaryData?.ageProportionMale?.age70 || 0 },
    ];
  };

  const getAgeProportionFemale = () => {
    return [
      { name: "0-3", value: summaryData?.ageProportionFemale?.age0_3 || 0 },
      { name: "4-12", value: summaryData?.ageProportionFemale?.age4_12 || 0 },
      { name: "13-19", value: summaryData?.ageProportionFemale?.age13_19 || 0 },
      { name: "20-34", value: summaryData?.ageProportionFemale?.age20_34 || 0 },
      { name: "35-49", value: summaryData?.ageProportionFemale?.age35_49 || 0 },
      { name: "50-69", value: summaryData?.ageProportionFemale?.age50_69 || 0 },
      { name: "70-", value: summaryData?.ageProportionFemale?.age70 || 0 },
    ];
  };

  const getMovingCount = () => {
    return (
      summaryData?.avsDaily?.pt0 +
      summaryData?.avsDaily?.pt50 +
      summaryData?.avsDaily?.pt75 +
      summaryData?.avsDaily?.pt100
    );
  };

  const getMovingAndStayingProportion = () => {
    return [
      { name: "歩行者", value: getMovingCount() || 0 },
      { name: "滞留者", value: summaryData?.avsDaily?.pt200 || 0 },
    ];
  };

  const getSummaryByAvsPoint = () => {
    return {
      xAxis: ["200pt", "100pt", "75pt", "50pt", "0pt"],
      series: [
        summaryData?.avsDaily?.pt200 || 0,
        summaryData?.avsDaily?.pt100 || 0,
        summaryData?.avsDaily?.pt75 || 0,
        summaryData?.avsDaily?.pt50 || 0,
        summaryData?.avsDaily?.pt0 || 0,
      ],
    };
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>ダッシュボード</Breadcrumb.Item>
        <Breadcrumb.Item>全体</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard dashboardCard">
          <div className="cardHeader">
            <div className="selArea">
              <AccountForm />
            </div>
            {/*<p className="time">*/}
            {/*  時刻 <MyTime />*/}
            {/*</p>*/}
          </div>
          <div className="cardBody dashboardBody">
            <Spin
              tip="読み込み..."
              wrapperClassName="cardLoading"
              spinning={loading}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card title={"媒体視聴可能人数・視聴態度推移"}>
                    <EchartsStackedArea />
                  </Card>
                </Col>
                <Col span={24}>
                  <DashboardGender />
                </Col>
                <Col span={8}>
                  <Card title="性別構成">
                    <EchartsPieForGenderRate data={getGenderProportion()} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="男性（年代構成）">
                    <EchartsPie data={getAgeProportionMale()} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="女性（年代構成）">
                    <EchartsPie data={getAgeProportionFemale()} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="進行方向分布">
                    <EchartsRadar data={summaryData?.angleRadar} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="AVSポイント(進行方向係数)">
                    <EchartsBar data={getSummaryByAvsPoint()} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="歩行者・滞留者比率">
                    <EchartsPie data={getMovingAndStayingProportion()} />
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title="24時間計測データ">
                    <EchartsStackedLine showTotal={true} />
                  </Card>
                </Col>
              </Row>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AccountMain);
