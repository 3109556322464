import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import { LineChart } from "echarts/charts";
import { AvsTrendGraph } from "../store/model/report.model";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

interface Props {
  data?: AvsTrendGraph;
}

const EchartsStackedLine = (props: Props) => {
  const { data } = props;

  let tmp: Array<any> = [];

  if (data?.legends?.length) {
    let names = Object.keys(data.series);
    names.sort(
      (a, b) =>
        parseInt(a.substring(0, a.length - 1)) -
        parseInt(b.substring(0, b.length))
    );
    for (let i = 0; i < data.legends.length; i++) {
      const temp = {
        name: names[i],
        type: "line",
        stack: "Total",
        areaStyle: {},
        lineStyle: {
          width: 1,
        },
        emphasis: {
          focus: "series",
        },
        data: Object.values(data.series)[i],
      };
      tmp.push(temp);
    }
  }

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      title: [
        {
          text: "",
          left: "center",
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: Array<any>, ticket: Object, callback: Function) => {
          let headerStr = "";
          if (params[0].axisValue.includes(":")) {
            let header = parseInt(params[0].axisValue.substr(0, 2));
            let oldHeader = header + ":00";
            let newHeader = header + 1 + ":00";
            headerStr = `${oldHeader}~${newHeader}<br/>`;
          } else {
            headerStr = params[0].axisValue + "<br />";
          }
          let sum = 0;
          let totalStr = "";
          let tipString = "";
          let indexColor;
          debugger;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="width: 150px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${
              params[i].seriesName
            }</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
            sum += parseInt(params[i].value);
          }
          totalStr += `<div style="width: 150px; margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style=" margin: 0;">Total</p><p style="margin: 0;">${NumberUtils.toThousands(
            sum
          )}</p></div>`;
          tipString = headerStr + totalStr + tipString;
          return tipString;
        },
      },
      toolbox: {
        feature: {},
      },
      // grid: {
      //   left: "3%",
      //   right: "4%",
      //   bottom: "3%",
      //   containLabel: true,
      // },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: data?.xAxis || [],
        },
      ],
      legend: {
        icon: "circle",
        data: data?.legends || [],
        padding: 20,
        textStyle: {
          rich: {
            name: {
              fontSize: 12,
            },
            value: {
              fontSize: 12,
              fontWeight: 900,
            },
          },
        },
      },
      yAxis: [
        {
          type: "value",
        },
      ],
      series: tmp,
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "1120px", height: " 350px" }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EchartsStackedLine);
