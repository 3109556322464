import { observer } from "mobx-react-lite";
import React from "react";
import { ReportContext, ReportStore } from "../store/report-store";
import ReportEdit from "./ReportEdit";
import { MyKeepAlive } from "../../../core/keep-alive/MyKeepAlive";

export default observer(() => {
  return (
    <ReportContext.Provider value={new ReportStore()}>
      <MyKeepAlive onlyOne={true}>
        <ReportEdit />
      </MyKeepAlive>
    </ReportContext.Provider>
  );
});
