import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { Badge } from 'antd';
import React from 'react';
import { MediaListModel } from '../../account/media/store/model/media.model';

const columns: ProColumns<MediaListModel>[] = [
	{
		title: '媒体ID',
		dataIndex: 'id',
		key: 'id',
		width: 120,
		align: 'center',
	},
	{
		title: '媒体名',
		dataIndex: 'name',
		key: 'name',
		ellipsis: true,
		align: 'left',
	},
	{
		title: '媒体所在地',
		dataIndex: 'location',
		key: 'location',
		ellipsis: true,
		align: 'left',
	},
	{
		title: 'アカウント名',
		dataIndex: 'accountName',
		key: 'account',
		ellipsis: true,
		align: 'left',
		render: (_, record: MediaListModel) => {
			return record?.account?.username;
		},
	},
	{
		title: '状態',
		dataIndex: 'online',
		key: 'online',
		align: 'center',
		render: (_, record: MediaListModel) => {
			if (record.online) {
				return <><Badge status="success"/>オンライン</>;
			} else {
				return <><Badge status="error"/>オフライン</>;
			}

		},
	},
];

export default columns;
