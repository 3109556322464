import { observer } from 'mobx-react-lite';
import { MyKeepAlive } from '../../../../core/keep-alive/MyKeepAlive';
import React from 'react';
import AgencyList from './AgencyList';
import { AgencyContext, AgencyStore } from '../store/agency-store';

export default observer(() => {
	return <AgencyContext.Provider value={new AgencyStore()}>
		<MyKeepAlive onlyOne={true}>
			<AgencyList/>
		</MyKeepAlive>
	</AgencyContext.Provider>;
});
