import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

interface Pros {
	value?: string;
	onChange?: (value: string) => void;
}

const {Option} = Select;

// @ts-ignore
const arrayForOptionHour = [...new Array(26).keys()];
// @ts-ignore
const arrayForOptionMin = [...new Array(60).keys()];

function TimeHHmm({value, onChange}: Pros) {
	const [hh, hhChange] = useState<string>('');
	const [mm, mmChange] = useState<string>('');

	const getTimes = (value: string) => {
		if (value) {
			const times = value.split(':');
			if (times.length === 2) {
				return times;
			}
		}
		return ['', ''];
	};

	// 检查更新 小时
	useEffect(() => {
		if (value && value.length > 1) {
			const [_hh] = getTimes(value);
			if (_hh !== mm) {
				hhChange(_hh);
			}
		}
	}, [hh, value, hhChange]);

	// 检查更新 分钟
	useEffect(() => {
		if (value && value.length > 1) {
			const [_, _mm] = getTimes(value);
			if (_mm !== mm) {
				mmChange(_mm);
			}
		}
	}, [mm, value, mmChange]);

	const onChangeHH = (value: string) => {
		hhChange(value);
		if (onChange) {
			onChange(`${value}:${mm || ''}`);
		}
	};
	const onChangeMM = (value: string) => {
		mmChange(value);
		if (onChange) {
			onChange(`${hh || ''}:${value}`);
		}
	};

	return (
		<>
			<Select
				style={{width: 60}}
				value={hh}
				onChange={onChangeHH}
			>
				{arrayForOptionHour.map((value) => {
					const _value = value < 10 ? `0${value}` : value;
					return <Option value={_value}>{_value}</Option>;
				})}
			</Select>
			<span> : </span>
			<Select
				style={{width: 60}}
				value={mm}
				onChange={onChangeMM}
			>
				{arrayForOptionMin.map((value) => {
					const _value = value < 10 ? `0${value}` : value;
					return <Option value={_value}>{_value}</Option>;
				})}
			</Select>
		</>
	);
}

export default TimeHHmm;
