import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './index.scss';
import LoginForm from './components/LoginForm';
import { LoginContext, LoginStore } from './store/login-store';
import { AuthContext } from '../../../store/auth-store';

const Login = () => {

	return <LoginContext.Provider value={new LoginStore(useContext(AuthContext))}>
		<div className="loginBox">
			<div className="loginMain">
				<div className="imgBg">
					<LoginForm/>
				</div>
			</div>
		</div>
	</LoginContext.Provider>;
};

export default observer(Login);
