import React from 'react';

interface Props {
  gender: string
  width: number
  height: number
  color: string
}

function GenderIcon({gender, width, height, color}: Props) {

  if (gender === "male") {
    return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="14824" width={width} height={height}>
      <path
        d="M511.4 213.2h-0.4c-50 0-91-40.6-91-90.6 0-50 40.8-90.6 91-90.6S602 72.6 602 122.6c0 24.2-9.4 47-26.6 64-17 17.2-39.8 26.6-64 26.6zM442.4 992c-28.8 0-54-21-54-60.8l2-555.2h-20v210c0 18.6-6 30.2-12.8 36.6-8.6 8.2-18.2 12.8-30.4 12.8-12.4 0-21.8-4.6-30.4-12.8-6.8-6.4-12.8-17.8-12.8-36.6V342.8c0-27.6 8.8-55.6 27.6-76.8 20.8-23.2 47.2-36 78-36h244.6c30.8 0 57.2 12.8 78 36.2 18.8 21.2 27.6 49 27.6 76.6V586c0 14.6-3.4 27.6-13.2 36.6-8.8 8-18.6 12.4-31 12.4s-22.2-4.4-31-12.4c-9.8-9-13.2-22-13.2-36.6V376h-18v555.2c0 39.4-26.8 60.8-55.6 60.8-26.8 0-52.6-18.6-54.8-59.6V650h-24v281.8c-1.4 39.4-27.6 60.2-56.6 60.2z"
        p-id="14825" fill={color}></path>
    </svg>;
  }

  if (gender === "female") {
    return <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="13210" width={width} height={height}>
      <path
        d="M572 992c-12.2 0-23.6-4.6-32-13.2-6.4-6.6-14.2-18.2-14.2-37.2V702.8H498v238.8c0 19-8 30.8-14.8 37.4-8.6 8.4-20.2 13-32.6 13-12.4 0-23.8-4.6-32.6-13-6.8-6.6-14.8-18.2-14.8-37.4V702.8h-87.8l89-341.2h-13.6L342 542.4c-8 26.2-27.4 35.6-42.8 35.6-13.2 0-25.8-6.4-34-17.4-9.6-12.8-12.2-29.8-7.2-47.8l58-208.2c8.4-30.4 41.4-76.4 97.4-78.6h196.8c56.8 2.4 88.2 52 97 77.8l0.2 0.8 58 208.6c4.8 18 2 35.2-7.8 48-8.2 10.8-20.8 17.2-33.8 17.2-15.4 0-34.6-9.6-42.6-36.2v-0.4l-48.6-180.2h-15.4l91 341.2H618v238.8c0 19-7.8 30.6-14.2 37.2-8.4 8.6-19.6 13.2-31.8 13.2z m-60.2-779.2c-48.4 0-87.8-40.6-87.8-90.4S463.4 32 511.8 32s87.8 40.6 87.8 90.4-39.4 90.4-87.8 90.4z"
        p-id="13211" fill={color}></path>
    </svg>;
  }

  return <></>;
};

export default GenderIcon;
