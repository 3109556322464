import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PATH_MEDIA_ADD } from '../../../../router/router-path';
import { useMyNavigate } from '../../../../core/navigate';
import MyTable from '../../../../components/MyTable';
import { MediaContext } from '../store/media-store';
import { MediaListModel } from '../store/model/media.model';
import columns from './columns';
import { OrderType } from '../../../../model/order-type';

const MediaList = () => {

	const navigate = useMyNavigate();

	const {listStore} = useContext(MediaContext);

	const sortHandle = (sorter: Record<string, any>) => {
		if (sorter.id) {
			return {orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC};
		}
		return {};
	};

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>設定・登録</Breadcrumb.Item>
			<Breadcrumb.Item>媒体管理一覧</Breadcrumb.Item>
		</Breadcrumb>

		<div className='subMainArea'>
			<MyTable<MediaListModel>
				listStore={listStore}
				columns={columns}
				rowKey={'id'}
				alive={true}
				search={false}
				sortHandle={sortHandle}
				toolBarRender={() => [
					<Button type="primary" key="primary"
							onClick={() => navigate(PATH_MEDIA_ADD)}>
						<PlusOutlined/>新規登録
					</Button>,
				]}/>
		</div>
	</>;
};

export default observer(MediaList);
