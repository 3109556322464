import React, { useContext } from 'react';
import { Breadcrumb, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { PATH_ACCOUNT_ADD } from '../../../../router/router-path';
import { PlusOutlined } from '@ant-design/icons/lib';
import { useMyNavigate } from '../../../../core/navigate';
import MyTable from '../../../../components/MyTable';
import columns from './columns';
import { AccountContext } from '../store/account-store';
import { OrderType } from '../../../../model/order-type';
import { useRoleType } from "../../../../hooks/use-auth";
import { RoleType } from "../../../../store/user.model";

const AccountList = () => {

		const navigate = useMyNavigate();
		const {listStore} = useContext(AccountContext);

		const roleType = useRoleType();

		const sortHandle = (sorter: Record<string, any>) => {
			if (sorter.id) {
				return {orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC};
			}
			return {};
		};

		return <>
			<Breadcrumb>
				<Breadcrumb.Item>設定・登録</Breadcrumb.Item>
				<Breadcrumb.Item>アカウント管理一覧</Breadcrumb.Item>
			</Breadcrumb>

			<div className='subMainArea'>
				<MyTable
					listStore={listStore}
					columns={columns}
					rowKey={'id'}
					alive={true}
					search={false}
					sortHandle={sortHandle}
					toolBarRender={() => [
						<Button hidden={roleType !== RoleType.ADMIN} type="primary" key="primary" onClick={() => navigate(PATH_ACCOUNT_ADD)}>
							<PlusOutlined/>新規登録
						</Button>,
					]}
				/>
			</div>
		</>;
	}
;

export default observer(AccountList);
