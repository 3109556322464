import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LabelLayout } from "echarts/features";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

interface Props {
  data?: Array<{ name: string; value: number }>;
  title?: string;
}
const EchartsPie = (props: Props) => {
  const { title } = props;
  const { data } = props;

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      tooltip: {
        trigger: "item",
      },
      legend: {
        icon: "circle",
        bottom: 20,
        width: "100%",
        padding: [5, 10],
        formatter: (name: string) => {
          let legend = data?.filter((item) => item.name === name)[0];
          return [
            `{name|${legend?.name}}`,
            `{value|${NumberUtils.toThousands(legend?.value)}}`,
          ].join("  ");
        },
        textStyle: {
          rich: {
            name: {
              fontSize: 12,
            },
            value: {
              fontSize: 12,
              fontWeight: 900,
            },
          },
        },
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "50%",
          data: data,
          avoidLabelOverlap: true,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            //饼图图形上的文本标签
            normal: {
              textStyle: {
                fontWeight: 500,
                fontSize: 11, //文字的字体大小
                color: "black",
              },
              formatter: function (data: any) {
                return (
                  data["name"] +
                  ": \n" +
                  NumberUtils.fixPoint(data.percent, 1) +
                  "%"
                );
              },
            },
            labelLine: {
              show: false, //隐藏标示线
            },
          },
          center: ["50%", "40%"],
        },
      ],
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: "340px", height: " 350px" }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EchartsPie);
