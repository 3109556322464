import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row, Statistic, Card } from "antd";
import EchartsPie from "../../components/EchartsPie";
import EchartsRadar from "../../components/EchartsRadar";
import { ReportContext } from "../../store/report-store";
import GenderIcon from "../../../../components/GenderIcon";
import EchartsStackedLine from "../../components/EchartsStackedLine";
import EchartsBar from "../../components/EchartsBar";
import EchartsPieForGenderRate from "../../components/EchartsPieForGenderRate";

const ReportDate = () => {
  const {
    viewDateStore: { data: viewDateData },
  } = useContext(ReportContext);

  const getMovingAndStayingProportion = () => {
    return [
      {name: "歩行者", value: viewDateData?.movingAndStaying?.series ? viewDateData?.movingAndStaying?.series[0] : 0},
      {name: "滞留者", value: viewDateData?.movingAndStaying?.series ? viewDateData?.movingAndStaying?.series[1] : 0}
    ];
  };

  return (
    <>
      <Row gutter={[16,16]}>
        <Col span={24}>
          <Card size={"small"} title={"広告視聴可能人数・視聴態度推移"}>
            <EchartsStackedLine data={viewDateData?.avsTrendGraph} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"広告視聴可能人数"}>
            <span className="subRedTtl">
              <Statistic
                className="gender-num"
                value={viewDateData?.totalImpression || 0}
                prefix={<span></span>}
                suffix="人"
              />
            </span>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"男性"}>
            <Statistic
              className="gender-num"
              value={viewDateData?.maleImpression || 0}
              prefix={<GenderIcon gender="male" width={24} height={24} color="#1890ff"/>}
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"女性"}>
            <Statistic
              className="gender-num"
              value={viewDateData?.femaleImpression || 0}
              prefix={<GenderIcon gender="female" width={24} height={24} color="#ff0000" />}
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"性別構成"}>
            <EchartsPieForGenderRate data={viewDateData?.genderProportion}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"男性（年代構成）"}>
            <EchartsPie data={viewDateData?.ageProportionMale}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"女性（年代構成）"}>
            <EchartsPie data={viewDateData?.ageProportionFemale}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"進行方向分布"}>
            <EchartsRadar data={viewDateData?.angleRadar} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"AVSポイント(進行方向係数)"}>
            <EchartsBar data={viewDateData?.avsPointGraph} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"歩行者・滞留者比率"}>
            <EchartsPie data={getMovingAndStayingProportion()}/>
          </Card>
        </Col>
        {/*<Col span={7}>*/}
        {/*  <Card size={"small"} title={"インプレッション数"}>*/}
        {/*    <Statistic*/}
        {/*      value={viewDateData?.impressionPoint || 0}*/}
        {/*      suffix="imp"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
        {/*<Col span={6}>*/}
        {/*  <Card size={"small"} title="視認者数">*/}
        {/*    <Statistic*/}
        {/*      value={viewDateData?.viewerCount || 0}*/}
        {/*      suffix="人"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      </Row>
    </>
  );
};

export default observer(ReportDate);
