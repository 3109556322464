/**
 * UserModel
 */
export interface UserModel {
	id: string,
	address: string;
	email: string;
	ownerName: string;
	ownerNameKana: string;
	password: string;
	telNo: string;
	username: string;
	role: Role;
}

export interface Role {
	id: RoleType,
	name: string,
}

export enum RoleType {
	ADMIN = 0,
	AD_MEDIA = 1,
	USER = 2
}
