import React, { useContext, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Space,
  InputNumber,
  Radio,
} from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_MEDIA } from "../../../../../router/router-path";
import { useAliveNotify } from "../../../../../core/keep-alive/use-keep-alive";
import { useFormInit } from "../../../../../core/form";
import { MediaContext } from "../../store/media-store";
import { useRoleType } from "../../../../../hooks/use-auth";
import { RoleType } from "../../../../../store/user.model";
import { AuthContext } from "../../../../../store/auth-store";
import { useAccountName } from "../../../../../hooks/use-account-name";
import TimeHHmm from "../../../../../components/TimeHHmm";
import { ConfigService } from "mobx-template";

const MediaEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const roleType = useRoleType();

  const accountName = useAccountName();

  const {
    detailStore: { data, loadData, setData, isBusy: loading },
    editStore: { submit, isBusy, body },
    delStore: { isBusy: delLoading },
  } = useContext(MediaContext);

  const {
    userStore: { data: userData },
  } = useContext(AuthContext);

  useEffect(() => {
    if (params?.id) {
      loadData({ id: params?.id }).then();
    } else {
      if (roleType !== RoleType.ADMIN) {
        setData({
          accountId: userData?.id,
          accountName: userData?.username,
        });
      }
      setData({
        hasAudio: true,
      });
    }
  }, [loadData, params?.id, setData, userData]);

  useFormInit(form, {
    ...data,
    times: [
      data?.opStartTime || body?.opStartTime,
      data?.opEndTime || body?.opEndTime,
    ],
  });

  const handleBlur = (accountId: string) => {
    if (accountId) {
      console.log("handleBlur");
      accountName.loadData(
        accountId,
        (accountName) => setData({ accountId, accountName }),
        () => setData({ accountId: accountId, accountName: "" })
      );
    }
  };
  const handleChange = (val: boolean) => {
    setData({
      hasAudio: val,
    });
  };

  const onFinish = async (values: any) => {
    let startTime = form.getFieldValue("opStartTime");
    let endTime = form.getFieldValue("opEndTime");
    let startHour = Number(startTime.split(":")[0]);
    let startMinute = Number(startTime.split(":")[1]);
    let endHour = Number(endTime.split(":")[0]);
    let endMinute = Number(endTime.split(":")[1]);

    if (
      startHour > endHour ||
      (startHour === endHour && startMinute > endMinute)
    ) {
      ConfigService.config.showErrorMessage({
        message: `媒体運営時間が誤っています。`,
      });
    } else {
      const { success } = await submit(values);
      if (success) {
        aliveNotify();
        navigate(PATH_MEDIA);
      }
    }
  };

  return (
    <Spin
      tip="読み込み..."
      spinning={loading || delLoading || accountName.isBusy}
    >
      <Form form={form} {...layout} onFinish={onFinish} className="form">
        <Row gutter={24}>
          <Col span={12}>
            {roleType === RoleType.ADMIN ? (
              <Form.Item
                name="accountId"
                label="アカウントID"
                rules={[
                  {
                    required: true,
                    message: "アカウントIDを入力してください",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="アカウントIDを入力してください"
                  onBlur={(e) => handleBlur(e.target.value)}
                />
              </Form.Item>
            ) : (
              <Form.Item name="accountId" label="アカウントID">
                <Input disabled />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item name="accountName" label="アカウント名">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="id" label="媒体ID">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="媒体名"
              rules={[{ required: true, message: "媒体名を入力してください" }]}
            >
              <Input maxLength={50} placeholder="媒体名を入力してください" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="location"
              label="媒体所在地"
              rules={[
                { required: true, message: "媒体所在地を入力してください" },
              ]}
            >
              <Input
                maxLength={255}
                placeholder="媒体所在地を入力してください"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="媒体運営時間" >
              <Space size="small" align="baseline">
                <Form.Item
                  label="開始"
                  name="opStartTime"
                  rules={[
                    {
                      required: true,
                      message: "媒体運営開始時間を入力してください",
                    },
                  ]}
                >
                  <TimeHHmm />
                </Form.Item>
                <span>〜</span>
                <Form.Item
                  label="終了"
                  name="opEndTime"
                  rules={[
                    {
                      required: true,
                      message: "媒体運営終了時間を入力してください",
                    },
                  ]}
                >
                  <TimeHHmm />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="hasAudio"
              label="音声"
              style={{ marginBottom: "10px" }}
            >
              <Radio.Group
                defaultValue={true}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              >
                <Radio value={true}>音声あり</Radio>
                <Radio value={false}>音声なし</Radio>
              </Radio.Group>
            </Form.Item>
            {data?.hasAudio && (
              <Form.Item
                name="audioComment"
                style={{ justifyContent: "right" }}
              >
                <Input
                  maxLength={100}
                  placeholder="無音時間帯があれば記入ください"
                />
              </Form.Item>
            )}
          </Col>

          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => navigate(PATH_MEDIA)}>キャンセル</Button>
              <Button
                type="primary"
                loading={isBusy}
                onClick={() => form.submit()}
              >
                {params.id ? "変更" : "登録"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
// ([0-1]\d|2[0-5]):[0-5]\d
export default observer(MediaEditForm);
