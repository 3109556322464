import {fetchGet, fetchPost, fetchPut} from '../../../../utils/request';
import {makeAutoObservable} from 'mobx';
import React from 'react';
import {fromListStore, fromModalStore, fromPageListStore, fromSubmitStore} from 'mobx-template';
import {AccountSelModel, MediaSelModel, SettingListModel, SettingModel} from './model/setting.model';
import {OrderType} from '../../../../model/order-type';

export class SettingStore {
    static apiPrefix = '/api/v1/edgebox';

    constructor() {
        makeAutoObservable(this);
    }

    listStore = fromPageListStore<SettingListModel, { orderType: OrderType }>(
        (params) => fetchPost(`${SettingStore.apiPrefix}/list`, params),
    );

    accountListStore = fromListStore<AccountSelModel>(
        (params) => fetchGet('/api/v1/common/account/codes'),
    );

    mediaListStore = fromListStore<MediaSelModel, { accountId: string }>(
        ({accountId}) => fetchGet(`/api/v1/common/media/codes?accountId=${accountId}`),
    );

    editStore = fromSubmitStore<SettingModel>(
        (body: SettingModel) => {
            const fetch = body.id ? fetchPut : fetchPost;
            return fetch(`${SettingStore.apiPrefix}`, body);
        },
    );

    modalStore = fromModalStore();
}

export const SettingContext = React.createContext<SettingStore>(null!);

