import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { Space } from 'antd';
import { MyLink } from '../../../../core/navigate';
import { PATH_AGENCY_UPDATE } from '../../../../router/router-path';
import React from 'react';
import { AgencyListModel } from '../store/model/agency.model';

const columns: ProColumns<AgencyListModel>[] = [
	{
		title: '代理店ID',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		sorter: true,
	},
	{
		title: '代理店名',
		dataIndex: 'name',
		key: 'name',
		align: 'center',
	},
	{
		title: '代理店住所',
		dataIndex: 'address',
		key: 'address',
		align: 'center',
	},
	{
		title: 'レポート登録数',
		dataIndex: 'loginNum',
		key: 'loginNum',
		align: 'center',
	},
	{
		title: 'アカウント名',
		dataIndex: 'accountName',
		key: 'accountName',
		align: 'center',
		render: (_, record: AgencyListModel) => {
			return record?.account?.username;
		},
	},
	{
		title: '登録日',
		dataIndex: 'createTime',
		key: 'createTime',
		align: 'center',
	},
	{
		title: '最終更新日',
		dataIndex: 'modifyTime',
		key: 'modifyTime',
		align: 'center',
	},
	{
		title: '変更',
		dataIndex: 'others',
		key: 'others',
		align: 'center',
		width: 120,
		render: (_, record: AgencyListModel) => {
			return <Space size="small">
				<MyLink to={`${PATH_AGENCY_UPDATE}/${record.id}`}>変更</MyLink>
			</Space>;
		},
	},
];

export default columns;
