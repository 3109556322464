import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row, Card, Statistic } from "antd";
import { DashboardContext } from "../store/dashboard-store";
import GenderIcon from "../../../components/GenderIcon";

const DashboardGender = () => {
  const {
    summaryStore: { data }
  } = useContext(DashboardContext);

  return (
    <div>
      <Row gutter={[16,16]}>
        <Col span={8}>
          <Card title="媒体視聴可能人数">
            <Statistic
              className="gender-num"
              value={data?.genderDaily?.male + data?.genderDaily?.female || 0}
              prefix={<span></span>}
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="男性">
            <Statistic
              className="gender-num"
              value={data?.genderDaily?.male || 0}
              prefix={<GenderIcon gender="male" width={32} height={32} color="#7dc5eb" />}
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="女性">
            <Statistic
              className="gender-num"
              value={data?.genderDaily?.female || 0}
              prefix={<GenderIcon gender="female" width={32} height={32} color="#e89abe" />}
              suffix="人"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(DashboardGender);
