import React from 'react';
import { observer } from 'mobx-react-lite';
import './index.scss';

const Profile = () => {

	return <div>Profile Info</div>;
};

export default observer(Profile);
