import React from 'react';
import { UseResult } from 'mobx-template';
import { Button, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons/lib';
import Popconfirm from 'antd/es/popconfirm';

interface Props<R> {
	handleDel: () => Promise<UseResult<R>>
	delSuccess?: (data?: R) => void
	el?: string
}

function MyDelConfirm<R = any>({handleDel, delSuccess, el}: Props<R>) {

	/**
	 * delete
	 */
	const deleteConfirm = async () => {
		const {success, data} = await handleDel();
		if (success && delSuccess) {
			delSuccess(data);
		}
	};

	const confirmModal = () => {
		Modal.confirm({
			title: '本当に削除しますか？',
			icon: <ExclamationCircleOutlined />,
			okText: '削除',
			cancelText: "キャンセル",
			onOk: deleteConfirm
		});
	};

	return <div className='cardHeaderBtn'>
		{el !== 'dropdown' && (
			<Popconfirm
				placement="leftBottom"
				title="本当に削除しますか？"
				okText="削除"
				cancelText="キャンセル"
				onConfirm={deleteConfirm}
			>
				<Button type='primary'><DeleteOutlined/>削除</Button>
			</Popconfirm>
		)}
		{el === 'dropdown' && (
			<div onClick={confirmModal}>削除</div>
		)}
	</div>;
};

export default MyDelConfirm;
