import { makeAutoObservable } from "mobx";
import React from "react";
import { fromListStore, fromObjStore, fromPageListStore } from "mobx-template";
import { CustomerInfo, DashboardModel } from "./model/dashboard.model";
import { fetchGet, fetchPost } from "../../../utils/request";
import { MediaListModel } from "../../account/media/store/model/media.model";
import { MediaSelModel } from "../../user/setting/store/model/setting.model";

export class DashboardStore {
  static apiPrefix = "/api/v1/dashboard";

  constructor() {
    makeAutoObservable(this);
  }

  customerInfoStore = fromObjStore<CustomerInfo>((params) =>
    fetchPost(`${DashboardStore.apiPrefix}/customerInfo`, params)
  );

  summaryStore = fromObjStore<
    DashboardModel,
    { date: string; mediaIds: number[] }
  >((params) => fetchPost(`${DashboardStore.apiPrefix}/summary`, params));

  mediaListStore = fromPageListStore<MediaListModel>((params) =>
    fetchPost("/api/v1/media/list", params)
  );

  mediaSelListStore = fromListStore<MediaSelModel>((params) =>
    fetchGet("/api/v1/common/media/codes")
  );

  dataZoom = [
    {
      type: "inside",
      start: 0,
      end: 100,
    },
    {
      start: 0,
      end: 100,
      labelFormatter: () => {
        return "";
      },
    },
  ];
  dataZoom24 = [
    {
      type: "inside",
      start: 0,
      end: 100,
    },
    {
      start: 0,
      end: 100,
      labelFormatter: () => {
        return "";
      },
    },
  ];
}

export const DashboardContext = React.createContext<DashboardStore>(null!);
