import React, { useContext } from "react";
import { Breadcrumb, Button } from "antd";
import { observer } from "mobx-react-lite";
import { PATH_REPORT_ADD } from "../../../router/router-path";
import { PlusOutlined } from "@ant-design/icons/lib";
import { useMyNavigate } from "../../../core/navigate";
import MyTable from "../../../components/MyTable";
import { Columns } from "./columns";
import { OrderType } from "../../../model/order-type";
import { ReportContext } from "../store/report-store";
import { useRoleType } from "../../../hooks/use-auth";
import { RoleType } from "../../../store/user.model";

const ReportList = () => {
  const navigate = useMyNavigate();
  const { listStore } = useContext(ReportContext);

  const roleType = useRoleType();

  const sortHandle = (sorter: Record<string, any>) => {
    if (sorter.id) {
      return {
        orderType: sorter.id === "ascend" ? OrderType.ASC : OrderType.DESC,
      };
    }
    return {};
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>レポート一覧</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <MyTable
          listStore={listStore}
          columns={Columns()}
          rowKey={"id"}
          alive={true}
          search={false}
          sortHandle={sortHandle}
          tableAlertRender={false}
          scroll={{ x: 900 }}
          toolBarRender={() => [
            <Button
              hidden={roleType === RoleType.USER}
              type="primary"
              key="primary"
              onClick={() => navigate(PATH_REPORT_ADD)}
            >
              <PlusOutlined />
              新規登録
            </Button>,
          ]}
        />
      </div>
    </>
  );
};
export default observer(ReportList);
