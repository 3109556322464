import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { LegendComponent, TitleComponent } from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { AngleRadar } from "../store/model/report.model";
import { NumberUtils } from "../../../utils/number-utils";

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

interface Props {
  data?: AngleRadar;
}

interface IndicatorData {
  name: string;
  max: number;
}

const EchartsRadar = (props: Props) => {
  const { data } = props;
  const indicatorData: Array<IndicatorData> = data?.indicator || [
    { name: "0pt(340°-20°)", max: 100 },
    { name: "0pt(300°-340°)", max: 100 },
    { name: "0pt(270°-300°)", max: 100 },
    { name: "50pt(240°-270°)", max: 100 },
    { name: "75pt(200°-240°)", max: 100 },
    { name: "100pt(160°-200°)", max: 100 },
    { name: "75pt(120°-160°)", max: 100 },
    { name: "50pt(90°-120°)", max: 100 },
    { name: "0pt(60°-90°)", max: 100 },
    { name: "0pt(20°-60°)", max: 100 },
  ];
  const indicatorMap = {
    "0pt(340°-20°)": "0pt",
    "0pt(300°-340°)": "0pt",
    "0pt(270°-300°)": "0pt",
    "0pt(60°-90°)": "0pt",
    "0pt(20°-60°)": "0pt",
    "50pt(240°-270°)": "50pt",
    "50pt(90°-120°)": "50pt",
    "75pt(200°-240°)": "75pt",
    "75pt(120°-160°)": "75pt",
    "100pt(160°-200°)": "100pt",
  };
  const seriesData: Array<number> = data?.series || [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  const getLegendData = () => {
    const legendData: Array<{ name: string; value: number }> = [];
    for (let i = 0; i < indicatorData.length; i++) {
      legendData.push({
        name: indicatorData[i].name,
        value: seriesData[i],
      });
    }
    const legendDataReal: Array<{ name: string; value: number }> = [];
    const legendDataObject = {};
    legendData.forEach((item) => {
      // @ts-ignore
      let name = indicatorMap[item.name];
      // @ts-ignore
      legendDataObject[name] = (legendDataObject[name] || 0) + item.value;
    });
    for (let legendDataObjectKey in legendDataObject) {
      legendDataReal.push({
        name: legendDataObjectKey,
        // @ts-ignore
        value: legendDataObject[legendDataObjectKey],
      });
    }
    return legendDataReal.map((item, index) => (
      <span
        style={{ fontSize: 12, display: "inline-block", marginRight: "10px" }}
        key={index}
      >
        <span style={{ fontSize: 12 }}>{item.name}&nbsp;&nbsp;</span>
        <span style={{ fontSize: 12, fontWeight: 600 }}>
          {NumberUtils.toThousands(item.value)}
        </span>
      </span>
    ));
  };

  const getOption = () => {
    return {
      color: [
        "#00c4cd",
        "#75bd7a",
        "#fdaf43",
        "#e66665",
        "#b452c5",
        "#42c1f8",
        "#dddee0",
        "#9ea1a0",
      ],
      tooltip: {
        trigger: "item",
        formatter: (params: any, ticket: Object, callback: Function) => {
          let headerStr = "進行方向分布詳細<br/>";
          let tipString = "";
          for (let i = 0; i < indicatorData.length; i++) {
            tipString += `<div style="width: 230px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;">
                            <p style="margin: 0;">
                                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${
                                  params.color
                                }">
                                </span>${indicatorData[i].name}
                            </p>
                            <p style="margin: 0;">${NumberUtils.toThousands(
                              params.value[i]
                            )}</p>
                          </div>`;
          }
          return headerStr + tipString;
        },
        position: "right",
      },
      radar: {
        indicator: indicatorData,
        radius: 68,
        center: ["50%", "35%"],
        axisName: {
          //show: false,
          color: "rgba(255,0,0,0)",
          backgroundColor: "rgba(255,0,0,0)",
          borderRadius: 3,
          padding: [3, 5],
        },
        axisLine: {
          // 坐标轴线
          show: false, // 默认显示，属性show控制显示与否
          data: [""],
        },
        splitArea: {
          show: false,
          areaStyle: {
            color: "rgba(255,0,0,0)", // 图表背景网格的颜色
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: "rgba(255,0,0,0)", // 图表背景网格线的颜色
          },
        },
      },
      series: [
        {
          type: "radar",
          data: [
            {
              value: seriesData,
              symbol: "rect",
              symbolSize: 6,
              lineStyle: {
                type: "dashed",
              },
            },
          ],
        },
      ],
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox report">
      <ReactEcharts
        option={getOption()}
        style={{ width: "320px", height: " 300px", top: "26px" }}
        ref={echartsEl}
      />
      <div
        style={{
          height: "50px",
          width: "250px",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {getLegendData()}
      </div>
    </div>
  );
};

export default observer(EchartsRadar);
