import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PATH_AGENCY_ADD } from '../../../../router/router-path';
import { useMyNavigate } from '../../../../core/navigate';
import MyTable from '../../../../components/MyTable';
import { AgencyContext } from '../store/agency-store';
import columns from './columns';
import { OrderType } from '../../../../model/order-type';

const AgencyList = () => {

	const navigate = useMyNavigate();

	const {listStore} = useContext(AgencyContext);

	const sortHandle = (sorter: Record<string, any>) => {
		if (sorter.id) {
			return {orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC};
		}
		return {};
	};

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>設定・登録</Breadcrumb.Item>
			<Breadcrumb.Item>代理店管理一覧</Breadcrumb.Item>
		</Breadcrumb>

		<div className='subMainArea'>
			<MyTable listStore={listStore}
					 columns={columns}
					 rowKey={'id'}
					 alive={true}
					 search={false}
					 sortHandle={sortHandle}
					 toolBarRender={() => [
						 <Button type="primary" key="primary" onClick={() => navigate(PATH_AGENCY_ADD)}>
							 <PlusOutlined/>新規登録
						 </Button>,
					 ]}/>
		</div>
	</>;
};

export default observer(AgencyList);
