import { useLocation } from 'react-router-dom';
import { autoFixContext, useActivate, useAliveController } from 'react-activation';
import { useEffect } from 'react';

let pending: string[] = [];

export const useAliveAutoFixContext = () => {
	autoFixContext(
		[require('react/jsx-runtime'), 'jsx', 'jsxs', 'jsxDEV'],
		// @ts-ignore
		[require('react/jsx-dev-runtime'), 'jsx', 'jsxs', 'jsxDEV'],
	);
};

/**
 * Notify cached page updates
 * The default key is the pathname of the previous page
 * @param key For matching
 */
export const useAliveNotify = (key?: string) => {
	const location = useLocation();
	let state = location?.state as { from: Location };
	const {getCachingNodes} = useAliveController();
	const pendingKey = key || state?.from?.pathname;
	return () => {
		if (pendingKey && getCachingNodes().some(item => item.name === pendingKey)) {
			pending.push(pendingKey);
		}
	};
};

/**
 * Accept update notification
 * @param effect Processing update
 * @param key For matching
 */
export const useAliveUpdate = (effect: () => void, key?: string) => {
	const location = useLocation();
	const pendingKey = key || location.pathname;
	useActivate(() => {
		if (pending.some(item => item === pendingKey)) {
			pending.splice(pending.indexOf(pendingKey), 1);
			effect();
		}
	});
};

/**
 * clear
 */
export const useAliveClear = () => {
	const {clear} = useAliveController();
	useEffect(() => {
		return () => {
			clear().then();
		};
	}, [clear]);
};
